import { Sounds, Sentence, Chart, Charts, Layers } from '../../../models';
import getLayersParser from './layers';

type Parser = (
  layers: Layers,
  muted?: boolean[]
) => {
  accepted: boolean;
  sentences: Sentence[];
  chart: Chart;
  charts: Charts;
  sounds: Sounds;
};

const getParser = (sounds: Sounds, bpm: number): Parser => {
  const layersParser = getLayersParser(sounds, bpm);
  const parser: Parser = (layers, muted?) => {
    const groups = {};
    const gMuted = {};
    const idxs = {};

    layers.forEach((l, idx) => {
      const g = l.group ?? 0;

      groups[g] = [...(groups[g] ?? []), l];
      if (muted) gMuted[g] = [...(gMuted[g] ?? []), muted[idx]];
      idxs[g] = [...(idxs[g] ?? []), idx];
    });

    const grouped = Object.keys(groups).reduce(
      (acc, g) => ({ ...acc, [g]: layersParser(groups[g], gMuted[g]) }),
      {}
    );
    const sentences = Array(layers.length);
    const charts = {};
    const soundsDict = {};
    let accepted = true;
    let chart = [] as Chart;

    Object.keys(groups).forEach((g) => {
      accepted = accepted && grouped[g].accepted;
      idxs[g].forEach((lIdx, idx) => {
        sentences[lIdx] = grouped[g].sentences[idx];
      });
      chart.push(...grouped[g].chart);
      charts[g] = grouped[g].chart;
      grouped[g].sounds.forEach((s) => {
        soundsDict[s.id] = s;
      });
    });
    chart = chart.sort((a, b) => a.start - b.start);

    return {
      accepted,
      sentences,
      chart,
      charts,
      sounds: Object.values(soundsDict),
    };
  };

  return parser;
};

export default getParser;
