import React, { ReactElement, useState, useRef } from 'react';
import { isMobile } from 'react-device-detect';

import { stopEventEffects } from '~/utils';
import { FONT_SIZES } from '~/constants/typography';
import VARIANTS from '~/constants/variants';
import COLORS from '~/constants/colors';
import Button from '~/components/buttons/Button';
import TextInput from '~/components/inputs/TextInput';
import Break from '~/components/flow/Break';

import urls from '../../../urls';

interface ShareUrlProps {
  code: string;
}

function ShareUrl({ code }: ShareUrlProps): ReactElement {
  const [isSelected, setIsSelected] = useState(false);
  const [copyLabel, setCopyLabel] = useState('Copy');
  const shareUrl = urls.read(code);
  const textInputRef = useRef<HTMLInputElement>(null);

  // Handlers
  const handleBlurLink = () => setIsSelected(false);
  const handleSelectLink = (event) => {
    if (!isMobile) stopEventEffects(event);
    if (!isMobile && isSelected) {
      event.target.blur();
      setIsSelected(false);
    } else {
      event.target.select();
      setIsSelected(true);
    }
  };
  const handleCopy = async (event) => {
    stopEventEffects(event);
    if (textInputRef.current) {
      textInputRef.current.select();
      setIsSelected(true);
    }

    // Needed for older browser support so type jank used to silence TypeScript
    if ((navigator as any).clipboard?.writeText)
      await navigator.clipboard.writeText(shareUrl);
    else (window as any).clipboardData?.setData('Text', shareUrl);

    // Hack for making React render button every time
    if (copyLabel === '✓') setCopyLabel(' ✓ ');
    else setCopyLabel('✓');
  };

  // Styling
  const shareUrlStyle = {
    display: 'inline-block',
    width: '21.5em',
    marginBottom: '-0.5em',
  };
  const textInputStyle = {
    width: '15.5em',
    padding: '0.5em',
    color: COLORS.gray,
    backgroundColor: COLORS.alabaster,
    borderColor: COLORS.silver,
    cursor: 'default',
    caretColor: COLORS.transparent,
  };
  const copyButtonStyle = {
    width: '3.5em',
    marginLeft: '0.5em',
    padding: '0.4em',
    backgroundColor: COLORS.doveGray,
    borderColor: COLORS.doveGray,
    ...(copyLabel.includes('✓') && {
      color: COLORS.tundora,
      backgroundColor: COLORS.white,
      borderColor: COLORS.tundora,
    }),
    borderRadius: '0.3em',
    fontSize: FONT_SIZES.text,
  };
  const breakStyle = {
    height: '1.5em',
  };

  return (
    <>
      <div style={shareUrlStyle}>
        <TextInput
          defaultValue={shareUrl}
          readOnly
          {...(isMobile && { onClick: handleSelectLink })}
          {...(!isMobile && { onMouseUp: handleSelectLink })}
          onBlur={handleBlurLink}
          variant={VARIANTS.disabled}
          style={textInputStyle}
          ref={textInputRef}
        />
        <Button
          label={copyLabel}
          callback={handleCopy}
          style={copyButtonStyle}
        />
      </div>
      <Break style={breakStyle} />
    </>
  );
}

export default ShareUrl;
