import React, { Dispatch, SetStateAction } from 'react';

import Gap from '~/components/flow/Gap';

import { Sounds, Sentence } from '../../../models';
import Grouper from './Grouper';
import Mute from './Mute';
import Remove from './Remove';
import InputBox from './InputBox';

interface LineProps {
  index: number;
  sounds?: Sounds;
  sentence: Sentence;
  patterns: string[];
  setPatterns: Dispatch<SetStateAction<string[]>>;
  groups: number[];
  setGroups: Dispatch<SetStateAction<number[]>>;
  muted: boolean[];
  setMuted: Dispatch<SetStateAction<boolean[]>>;
  disabled?: boolean;
  addLine: () => void;
  removeLine: () => void;
}

function Line({
  index,
  sounds,
  sentence,
  patterns,
  setPatterns,
  groups,
  setGroups,
  muted,
  setMuted,
  disabled,
  addLine,
  removeLine,
}: LineProps) {
  return (
    <div>
      <Grouper index={index} groups={groups} setGroups={setGroups} />
      <Mute index={index} muted={muted} setMuted={setMuted} />
      <InputBox
        index={index}
        patterns={patterns}
        setPatterns={setPatterns}
        sentence={sentence}
        sounds={sounds}
        disabled={disabled}
        addLine={addLine}
        removeLine={removeLine}
      />
      <Remove
        totalLines={patterns.length}
        onDelete={removeLine}
        disabled={disabled}
      />
      {index < patterns.length - 1 && <Gap />}
    </div>
  );
}

export default Line;
