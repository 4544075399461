import React, { useState, useEffect, useRef } from 'react';

import COLORS from '~/constants/colors';
import Button from '~/components/buttons/Button';
import Modal from '~/components/Modal';
import { FONT_SIZES } from '~/constants/typography';
import VARIANTS from '~/constants/variants';
import Announcement from '~/components/Announcement';
import Break from '~/components/flow/Break';

import { matchYouTube } from '../../../helpers';
import { Beat } from '../../../models';
import { readRecording } from '../../../services';
import AudioPlayer from './AudioPlayer';
import VideoPlayer from './VideoPlayer';

interface DemoProps {
  beat: Beat;
}

function Demo({ beat }: DemoProps) {
  const [showModal, setShowModal] = useState(false);

  // YouTube
  const youTubeMatched = matchYouTube(beat.youTube ?? '');
  const youTubeId = youTubeMatched?.[8];
  const youTubeRef = useRef<HTMLIFrameElement>(null);
  const pauseYouTube = () =>
    youTubeRef.current?.contentWindow?.postMessage(
      '{"event":"command","func":"pauseVideo","args":""}',
      '*'
    );
  const youTubeStart = youTubeMatched?.[12];
  let youTubeSrc = `https://www.youtube.com/embed/${youTubeId}?fs=0&iv_load_policy=3&modestbranding=1&playsinline=1&enablejsapi=1`;

  if (youTubeStart) youTubeSrc = `${youTubeSrc}&start=${youTubeStart}`;

  // Beatbox Share
  const [bshData, setBshData] = useState<[string, string]>(['', '']);

  useEffect(() => {
    (async () => {
      if (beat.clip) {
        try {
          const recording = await readRecording(
            beat.clip.recording.url,
            beat.clip.recording.mime
          );
          const type = beat.clip.recording.mime.startsWith('audio')
            ? 'audio'
            : 'video';
          const url = URL.createObjectURL(recording);

          setBshData([type, url]);
        } catch {
          setBshData(['error', '']);
        }
      }
    })();
  }, [beat.clip]);

  // Handlers
  const hideModal = () => {
    if (beat.youTube) pauseYouTube();
    setShowModal(false);
  };

  // Styling
  const buttonStyle = {
    float: 'left' as const,
    width: '4.3em',
    padding: '0.3em',
    color: COLORS.tundora,
    borderColor: COLORS.tundora,
    borderRadius: '0.3em',
    fontSize: FONT_SIZES.text,
    boxShadow: `-0.07em 0.07em ${COLORS.black25}`,
  };
  const modalStyle = {
    width: 'min(95vw, 30em)',
    padding: '1em',
    boxSizing: 'border-box' as const,
    fontSize: FONT_SIZES.action,
    overflowX: 'hidden' as const,
    overflowY: 'scroll' as const,
  };
  const youTubeStyle = {
    borderRadius: '0.5em',
    marginBottom: '0.5em',
  };

  return beat.youTube || beat.clip ? (
    <>
      <Button
        label="Demo"
        variant={VARIANTS.neutral}
        callback={() => setShowModal(true)}
        style={buttonStyle}
      />
      <Modal visible={showModal} hider={hideModal} style={modalStyle}>
        {beat.youTube && (
          <iframe
            title="BeatbloxControlsDemoYouTubeIFrame"
            width={475}
            height={268}
            src={youTubeSrc}
            frameBorder="0"
            allow="fullscreen; encrypted-media"
            style={youTubeStyle}
            ref={youTubeRef}
          />
        )}
        {beat.clip && (
          <>
            {bshData[0] === 'error' && (
              <Announcement>
                Clip not found, it may have been deleted
              </Announcement>
            )}
            {bshData[0] === 'audio' && (
              <AudioPlayer visible={showModal} url={bshData[1]} />
            )}
            {bshData[0] === 'video' && (
              <VideoPlayer visible={showModal} url={bshData[1]} />
            )}
            <Break />
          </>
        )}
        <Button
          label="Close"
          width="100%"
          variant={VARIANTS.neutral}
          callback={hideModal}
        />
      </Modal>
    </>
  ) : (
    <></>
  );
}

export default Demo;
