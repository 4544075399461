import { SyntheticEvent } from 'react';
import axios from 'axios';

import { stopEventEffects } from '~/utils';
import { getArrayBuffer } from '~/helpers/media';

import { Sound, Note, Chart } from './models';
import { MARKS } from './constants/language';

export const getPx = (v?: number): string => `${v ?? 0}px`;

export const getMaxTiming = (chart: Note[]): number => {
  const lastNote = chart.reduce(
    (acc, n) => ((acc.end ?? acc.start) > (n.end ?? n.start) ? acc : n),
    { end: 0 } as Note
  );

  return lastNote.end ?? lastNote.start;
};

export const extractSounds = (chart: Chart): Record<number, Sound> =>
  chart.map((n) => n.sound).reduce((acc, s) => ({ ...acc, [s.id]: s }), {});

export const cleanCarets = (p: string): string => {
  const re = new RegExp(`\\${MARKS.caret}`, 'g');

  return p.replace(re, '');
};

export const getPlaySample =
  (url: string) =>
  async (event: SyntheticEvent): Promise<void> => {
    const response = await axios
      .get(url, { responseType: 'blob' })
      .catch(() => {});

    stopEventEffects(event);
    if (response) {
      const context = new AudioContext();
      const blob = response.data;
      const arrayBuffer = await getArrayBuffer(blob);
      const audioBuffer = await context.decodeAudioData(arrayBuffer);
      const bufferSourceNode = new AudioBufferSourceNode(context, {
        buffer: audioBuffer,
      });
      const gainNode = new GainNode(context);

      bufferSourceNode.connect(gainNode);
      gainNode.connect(context.destination);
      bufferSourceNode.onended = () => {
        bufferSourceNode.disconnect();
        gainNode.disconnect();
        if (context.state !== 'closed') context.close();
      };
      gainNode.gain.setValueAtTime(1, 0.5);
      gainNode.gain.exponentialRampToValueAtTime(0.01, 0.6);
      bufferSourceNode.start(0, 0, 0.6);
    }
  };

export const matchYouTube = (value: string): string[] | undefined => {
  const linkRegex =
    /(https?:\/\/)?(((m|www)\.)?(youtube(-nocookie)?|youtube.googleapis)\.com.*(v\/|v=|vi=|vi\/|e\/|embed\/|user\/.*\/u\/\d+\/)|youtu\.be\/)([_0-9a-z-]+)/i;
  const startRegex = /^.*((\?t|\?start|&t|&start)=(\d+))+.*/i;
  const linkMatched = value.match(linkRegex) ?? undefined;
  const startMatched = value.match(startRegex) ?? [];

  return linkMatched?.concat(startMatched);
};

export const isYouTubeLink = (value: string): boolean => !!matchYouTube(value);

export const isBshLink = (value: string): boolean => {
  const linkRegex =
    /^((?:https?:)?\/\/)?((?:www)\.)?((?:beatbox\.sh))(\/(?:[a-zA-Z0-9]+))$/i;

  return !!value.match(linkRegex);
};
