import React, { ReactElement, useState, Dispatch, SetStateAction } from 'react';

import { FONT_SIZES } from '~/constants/typography';
import VARIANTS from '~/constants/variants';
import Button from '~/components/buttons/Button';
import Modal from '~/components/Modal';
import Gap from '~/components/flow/Gap';
import TextButton from '~/components/buttons/TextButton';
import { STATUS_CODES } from '~/constants/api';
import Announcement from '~/components/Announcement';
import Break from '~/components/flow/Break';

import EffectOptions from './EffectOptions';
import {
  GAIN_OPTIONS,
  ATTENUATION_OPTIONS,
  COMPRESSION_OPTIONS,
  DISTORTION_OPTIONS,
} from '../constants';
import {
  getCustomPreset,
  clearCustomPreset,
  muxVideo,
} from '../../../services';
import { cookOffline } from '../../../utils';

interface CustomModalProps {
  cookedMedia: Blob;
  rawAudioBuffer: AudioBuffer;
  setCookedMedia: Dispatch<SetStateAction<Blob | undefined>>;
  setMastering: Dispatch<SetStateAction<string>>;
  setErrorText: Dispatch<SetStateAction<string>>;
}

function CustomModal({
  cookedMedia,
  rawAudioBuffer,
  setCookedMedia,
  setMastering,
  setErrorText,
}: CustomModalProps): ReactElement {
  const [showCustom, setShowCustom] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showLagWarning, setShowLagWarning] = useState(false);
  const [reset, setReset] = useState(false);

  // Handlers
  const handleShowCustom = () => setShowCustom(true);
  const handleHideCustom = () => {
    (document.activeElement as HTMLElement)?.blur();
    setShowCustom(false);
  };
  const handleResetCustom = () => {
    clearCustomPreset();
    setReset(!reset);
  };
  const handleApply = async () => {
    try {
      setIsLoading(true);
      if (cookedMedia.type.startsWith('video')) setShowLagWarning(true);
      (document.activeElement as HTMLElement)?.blur();

      // Cook offline
      const customPreset = getCustomPreset();
      const newConfig = { preset: customPreset };
      const newCookedAudio = await cookOffline(rawAudioBuffer, newConfig);

      if (cookedMedia.type.startsWith('video'))
        setCookedMedia(await muxVideo(cookedMedia, newCookedAudio));
      else setCookedMedia(newCookedAudio);
      setMastering('');
      setErrorText('');
    } catch (error: any) {
      if (error.response?.status === STATUS_CODES.tooManyRequests)
        setErrorText('Server is tired, please try again in 30 seconds');
      else setErrorText('Custom mastering failed, please try again later');
    }
    setIsLoading(false);
    setShowLagWarning(false);
    setShowCustom(false);
  };

  // Styling
  const actionStyle = {
    width: '6.3em',
    margin: '0 0.5em',
    fontSize: FONT_SIZES.text,
  };
  const optionInputStyle = {
    marginBottom: '1em',
  };
  const modalButtonsStyle = {
    float: 'right' as const,
    marginTop: '-0.5em',
  };
  const applyStyle = {
    width: '5em',
    margin: '0.5em 0 0',
  };
  const cancelStyle = {
    ...applyStyle,
    margin: '0 0.5em 0 0',
  };

  return (
    <>
      <Button
        variant={VARIANTS.secondary}
        callback={handleShowCustom}
        label="Custom"
        style={actionStyle}
      />
      <Modal visible={showCustom} title="Custom" hider={handleHideCustom}>
        Apply personalized mastering. Click{' '}
        <TextButton callback={handleResetCustom}>here</TextButton> to reset.
        <Gap />
        <EffectOptions
          header="Gain"
          subtext="Control signal amplification"
          options={GAIN_OPTIONS}
          reset={reset}
          style={optionInputStyle}
        />
        <Gap />
        <EffectOptions
          header="Attenuation"
          subtext="Control frequencies to suppress"
          options={ATTENUATION_OPTIONS}
          reset={reset}
          style={optionInputStyle}
        />
        <Gap />
        <EffectOptions
          header="Compression"
          subtext="Control range of dynamics"
          options={COMPRESSION_OPTIONS}
          reset={reset}
          style={optionInputStyle}
        />
        <Gap />
        <EffectOptions
          header="Distortion"
          subtext="Control waveform deformation"
          options={DISTORTION_OPTIONS}
          reset={reset}
        />
        <Break />
        {showLagWarning && (
          <>
            <Announcement variant={VARIANTS.warning}>
              Longer videos may take a while to master
            </Announcement>
            <Gap />
          </>
        )}
        <div style={modalButtonsStyle}>
          <Button
            label="Cancel"
            variant={VARIANTS.secondary}
            callback={handleHideCustom}
            style={cancelStyle}
          />
          <Button
            label="Apply"
            loading={isLoading}
            variant={VARIANTS.warning}
            callback={handleApply}
            style={applyStyle}
          />
        </div>
      </Modal>
    </>
  );
}

export default CustomModal;
