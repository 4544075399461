import React, { ReactElement } from 'react';

import COLORS from '~/constants/colors';

import { getPx } from '../../../helpers';
import { GROUPS } from '../../../constants/defaults';

interface BarProps {
  height: number;
  timing: number;
  minTiming: number;
  maxTiming: number;
  fuzzy: boolean;
  progress: number;
  group?: number;
}

function Bar({
  height,
  timing,
  minTiming,
  maxTiming,
  fuzzy,
  progress = 0,
  group,
}: BarProps): ReactElement {
  const notFuzzyWidth = 2;
  let barColor = COLORS.gray;

  if (!fuzzy || (progress > timing && timing >= minTiming))
    barColor = GROUPS[group ?? 0];

  // Styling
  const barStyle = {
    position: 'absolute' as const,
    width: 0,
    height: getPx(height),
    left: `${(timing / maxTiming) * 100}%`,
    top: 0,
    borderColor: barColor,
    borderStyle: fuzzy ? 'dashed' : 'solid',
    borderWidth: `0 0 0 ${getPx(fuzzy ? 1 : notFuzzyWidth)}`,
    transform: `translate(-${getPx(fuzzy ? 0.5 : notFuzzyWidth / 2)})`,
    zIndex: 1,
  };

  return <div style={barStyle} />;
}

export default Bar;
