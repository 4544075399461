import React, { ReactElement, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { FONT_SIZES } from '~/constants/typography';
import Gap from '~/components/flow/Gap';
import Break from '~/components/flow/Break';

import { Charts, Sounds, Beat } from '../../models';
import { BEAT } from '../../constants/defaults';
import { readSounds, readBeat } from '../../services';
import Script from '../../components/Script';
import Controls from '../../components/Controls';
import Visualize from '../../components/Visualize';
import Help from '../../components/Help';
import Title from './components/Title';
import NotFound from '../NotFound';
import ShareUrl from './components/ShareUrl';
import Options from './components/Options';

function Read(): ReactElement {
  const { code } = useParams();
  const [notFound, setNotFound] = useState<ReactElement>();
  const [sounds, setSounds] = useState<Sounds>();
  const [beat, setBeat] = useState(BEAT);
  const [charts, setCharts] = useState<Charts>({});
  const [progress, setProgress] = useState(0);
  const [loaded, setLoaded] = useState<Beat>();
  const state = sounds ? 'ready' : 'loading';

  useEffect(() => {
    (async () => {
      if (code) {
        try {
          const ss = await readSounds();
          const l = await readBeat(code);

          setSounds(ss);
          setLoaded(l);
        } catch {
          setNotFound(<NotFound withCode />);
        }
      }
    })();
  }, [code]);

  // Styling
  const safeWidth = '70ch';
  const readStyle = {
    minWidth: safeWidth,
    fontSize: FONT_SIZES.text,
  };
  const containerStyle = {
    width: `max(${safeWidth}, 100vw)`,
    textAlign: 'center' as const,
  };

  return (
    <div style={readStyle}>
      <div style={containerStyle}>
        <Help sounds={sounds} />
        <Break />
        {notFound}
        {!notFound && (
          <>
            <Title beat={loaded} />
            {code && <ShareUrl code={code} />}
            <Visualize
              beat={beat}
              charts={charts}
              state={state}
              progress={progress}
            />
            <Gap />
            <Controls
              beat={beat}
              setBeat={setBeat}
              charts={charts}
              setProgress={setProgress}
              disabled={!sounds}
              loaded={loaded}
            />
            <Break />
            <Script
              sounds={sounds}
              beat={beat}
              setBeat={setBeat}
              setCharts={setCharts}
              disabled={!sounds}
              loaded={loaded}
            />
            <Options beat={beat} loaded={loaded} />
          </>
        )}
      </div>
    </div>
  );
}

export default Read;
