import { Dispatch, SetStateAction } from 'react';
import axios from 'axios';
import { FieldError } from 'react-hook-form';

import { StringLiteral } from '~/models';
import { isObject } from '~/utils';
import { isBadRequest } from '~/helpers/error';

export const getFormErrorHandler =
  <T>(
    defaultInfo: Record<string, any>,
    setError: (name: StringLiteral<T>, error: FieldError) => void,
    setFail: Dispatch<SetStateAction<string | undefined>>,
    resolve?: () => void
  ) =>
  (error: Error): void => {
    if (isBadRequest(error)) {
      const errorDetails = error.response.data.error.details;
      const formKeys = Object.keys(defaultInfo);
      const errorKeys = Object.keys(errorDetails);

      if (errorKeys.length > 0)
        errorKeys.forEach((k) => {
          if (formKeys.includes(k))
            setError(k as StringLiteral<T>, {
              type: 'custom',
              message: errorDetails[k],
            });
        });
      else setFail(error.response?.data.error.message);
    } else if (axios.isAxiosError(error))
      setFail(error.response?.data.message?.[0]?.messages[0]?.message);
    resolve?.();
  };

export const flattenData = <T>(data: Record<string, any>): T => {
  let flattened = data?.data || data;

  if (isObject(flattened)) {
    if (flattened.id)
      flattened = { ...(flattened.attributes ?? flattened), id: flattened.id };
    flattened = Object.keys(flattened).reduce(
      (acc, k) => ({
        ...acc,
        [k]: flattenData(flattened[k]),
      }),
      {} as Record<string, any>
    );
  } else if (Array.isArray(flattened))
    flattened = flattened.map((v) => flattenData(v));

  return flattened;
};
