import { Beat } from '../../models';

export default [
  {
    id: -1,
    title: 'Helium BEST DRUM AND BASS PATTERNS #27',
    loops: 3,
    bpm: 165,
    layers: [{ pattern: 'B[Ts] KB tkB- [Ts]K tK-B K-tK -BK- tK[Peh]K' }],
    sounds: [],
    youTube: 'https://www.youtube.com/embed/1tEcW-LgwH0?start=333',
  },
  {
    id: -1,
    title: 'Helium GBB 2020 Wildcard',
    loops: 3,
    bpm: 186,
    layers: [
      { pattern: '[Bs] [Pf][df] [OD]B [Pf][OD] B-[k1]K [Pf]t KB [Pf]K' },
    ],
    sounds: [],
    youTube: 'https://www.youtube.com/embed/BuyEzVPXMLc?start=56',
  },
  {
    id: -1,
    title: 'Zekka SBX Kickback 2021 Wildcard',
    loops: 2,
    bpm: 127,
    layers: [
      {
        pattern:
          'B tB[k1][k1] [K1][Peh]-K tB [B2][hub][mg][Peh] t[lrs][B2]- [K1][Peh]-K t-B[d1]B-B-',
      },
      { pattern: '[osb]>' },
    ],
    sounds: [],
    youTube: 'https://www.youtube.com/embed/Smz6NxE2mcE?start=53',
  },
  {
    id: -1,
    title: 'D-Low SBX Kickback 2021 Quarterfinal',
    loops: 1,
    bpm: 125,
    layers: [
      {
        pattern:
          '-[hcp] -[hcp] -[hcp] -[hcp] [B1]-[hcp]B [B1]B[hcp]- K-[hcp]B [B1]-[hcp]B [B1]B[B1]- [hcp]B[B1]- K-[hcp]B [B1]B[B1]B [B1]-[hcp]B [B1]B[hcp]- K-[hcp]B [B1]-[hcp]B [B1]B[hcp]- [hcp]B[B1]- KB tB--',
      },
      {
        pattern:
          '- - - - [hilr]- -[hilr] [hilr] [hilr] -[hilr] -[hilr] [hilr] - [hilr]- -[hilr] [hilr] [hilr] -[hilr] -[hilr] -[osb] -[osb]>>',
      },
    ],
    sounds: [],
    youTube: 'https://www.youtube.com/embed/CyBlilUzSFk?start=234',
  },
  {
    id: -1,
    title: 'Zer0 Duck House',
    loops: 2,
    bpm: 130,
    layers: [
      {
        pattern:
          '-[dk]t[icr] k[dk]t[icr] -f[stb]> kf[stb]> -f[stb]> kf[stb]> -f[stb].k kf[stb]-', // TODO: last rest in pattern should be sustain but parser bug so using rest for now
      },
      { pattern: 'G G G G G G G G' },
    ],
    sounds: [],
    youTube: 'https://www.youtube.com/embed/TQsiXWltMKs?start=339',
  },
  {
    id: -1,
    title: 'NaPoM This Is Just the Beginning',
    loops: 1,
    bpm: 125,
    layers: [
      {
        pattern:
          '[B1] [lr]-B k [lr]-B [B1] [lr]-B k [lr]-B [B1] [lr]-B k [lr]-B [B1] [lr]-B [B1] [lr]-B',
      },
      {
        pattern:
          '[slr] - [hilr] - [slr][slr][slr] - [hilr] - [hilr] - [hilr] - [slr][slr][slr] - [slr][slr][slr]',
      },
    ],
    sounds: [],
    youTube: 'https://www.youtube.com/embed/OY-Ec8zX2bw?start=185',
  },
] as Beat[];
