import React, {
  ReactElement,
  useState,
  useEffect,
  useLayoutEffect,
} from 'react';
import { useForm } from 'react-hook-form';
import { useParams, useSearchParams, useNavigate } from 'react-router-dom';

import VARIANTS from '~/constants/variants';
import { useAuth } from '~/providers/Auth';
import COLORS from '~/constants/colors';
import TextInput from '~/components/inputs/TextInput';
import Subtext from '~/components/titles/Subtext';
import Button from '~/components/buttons/Button';
import Gap from '~/components/flow/Gap';
import { getFormErrorHandler } from '~/helpers/journal';
import Announcement from '~/components/Announcement';
import Header from '~/components/titles/Header';
import Divider from '~/components/flow/Divider';
import Link from '~/components/flow/Link';
import Code from '~/components/flow/Code';
import Modal from '~/components/Modal';
import { FONT_SIZES } from '~/constants/typography';

import { DEFAULT_TUTORIAL_INFO } from '../constants/defaults';
import CONTENT from '../constants/content';
import { useBeatboxContext } from '../providers/BeatboxProvider';
import uris from '../uris';
import {
  hasPermission,
  infoFromContent,
  getRelationOptions,
  isYouTubeLink,
  isInstagramLink,
} from '../utils';
import OptionSelect from './OptionSelect';
import CurrentList from './CurrentList';

function TutorialForm(): ReactElement {
  const [fail, setFail] = useState<string>();
  const [showDeleter, setShowDeleter] = useState(false);
  const { state: authState } = useAuth();
  const {
    state: beatboxState,
    listTechniques,
    addTutorial,
    setTutorial,
    listTutorials,
    editTutorial,
    deleteTutorial,
  } = useBeatboxContext();
  const tutorialId = Number(useParams().tutorialId);
  const [searchParams] = useSearchParams();
  const techniqueId = Number(searchParams.get('techniqueId'));
  const page = Number(searchParams.get('page')) || 1;

  // Form
  const defaultValues =
    (tutorialId &&
      infoFromContent(beatboxState.tutorial, DEFAULT_TUTORIAL_INFO)) ||
    DEFAULT_TUTORIAL_INFO;
  const {
    register,
    control,
    handleSubmit,
    setError,
    reset,
    getValues,
    formState: { errors, isSubmitting },
  } = useForm({ defaultValues });
  const youtubeOpts = {
    validate: (value) =>
      isYouTubeLink(value) ||
      (value === '' && isInstagramLink(getValues('instagram'))),
  };
  const instagramOpts = {
    validate: (value) =>
      isInstagramLink(value) ||
      (value === '' && isYouTubeLink(getValues('youtube'))),
  };
  const relationRules = {
    validate: (list) =>
      Array.isArray(list) &&
      list.map((e) => Number.isInteger(e)).reduce((acc, e) => acc && e, true),
  };

  if (techniqueId && !defaultValues.techniques.includes(techniqueId))
    defaultValues.techniques = [...defaultValues.techniques, techniqueId];

  // Rendering
  const primaryButtonText = tutorialId ? 'Update' : 'Submit';
  const primaryButtonLoadingText = tutorialId ? 'Changing...' : 'Creating...';
  const deleteButtonText = 'Delete';
  const deleteButtonLoadingText = 'Removing...';
  const resetButtonText = tutorialId ? 'Reset' : 'Clear';

  // Handlers
  const navigate = useNavigate();
  const onSubmit = (tutorialInfo) => {
    return new Promise<void>((resolve) => {
      setFail(undefined);
      (tutorialId
        ? editTutorial(tutorialId, tutorialInfo)
        : addTutorial(tutorialInfo)
      )
        .then(async (res) => {
          navigate(uris.exploreTutorial(tutorialId || res));
        })
        .catch(
          getFormErrorHandler(DEFAULT_TUTORIAL_INFO, setError, setFail, resolve)
        );
    });
  };
  const onDelete = () => {
    return new Promise<void>(() => {
      setFail(undefined);
      deleteTutorial(tutorialId).then(() => {
        setShowDeleter(false);
        navigate(uris.manageTutorials);
      });
    });
  };
  const handleReset = () => reset();
  const handleShowDeleter = () => setShowDeleter(true);

  // Styling
  const textInputStyle = {
    width: 'min(320px, 75vw)',
  };
  const selectStyle = {
    width: 'min(335px, 80vw)',
  };
  const primaryButtonStyle = {
    width: '9em',
    margin: '0.13em 0.5em 0.13em 0',
  };
  const resetButtonStyle = {
    ...primaryButtonStyle,
    margin: '0.13em 0 0.13em 0',
  };
  const deleteButtonStyle = {
    marginLeft: '2.5%',
  };
  const showDeleteStyle = {
    color: COLORS.mandy,
    cursor: 'pointer',
  };
  const deleterContainerStyle = {
    padding: '0em 0.5em',
  };
  const subheaderStyle = {
    fontSize: FONT_SIZES.action,
  };

  // Set tutorial for edit
  useLayoutEffect(() => {
    (async () => {
      if (
        hasPermission(authState, CONTENT.tutorials) &&
        tutorialId &&
        tutorialId !== beatboxState.tutorial?.id
      )
        await setTutorial(tutorialId);
    })();
  }, [tutorialId]);
  useEffect(() => reset(defaultValues), [beatboxState.tutorial, tutorialId]);

  // List tutorials
  useEffect(() => {
    (async () => {
      if (
        hasPermission(authState, CONTENT.tutorials) &&
        (!beatboxState.tutorials ||
          !beatboxState.tutorial ||
          page !== beatboxState.tutorials?.meta.pagination.page)
      )
        await listTutorials(page);
    })();
  }, [beatboxState.subject, beatboxState.tutorial, page]);

  // List techniques
  useEffect(() => {
    (async () => {
      if (
        hasPermission(authState, CONTENT.tutorials) &&
        !beatboxState.techniques
      )
        await listTechniques();
    })();
  }, [beatboxState.subject]);

  return (
    <>
      {!!tutorialId && (
        <>
          Go back to <Link href={uris.manageTutorials}>Add Tutorial</Link> or
          view in <Link href={uris.exploreTutorial(tutorialId)}>Explore</Link>
        </>
      )}
      <Header>
        {tutorialId ? 'Edit' : 'Add'} Tutorial
        {!!tutorialId && (
          <>
            {' '}
            <Code>{tutorialId}</Code>
          </>
        )}
      </Header>
      {fail && (
        <>
          <Announcement>{fail}</Announcement>
          <Gap />
        </>
      )}
      <form>
        <div>
          <TextInput
            {...register('youtube', youtubeOpts)}
            placeholder="YouTube link"
            style={textInputStyle}
          />
          {errors.youtube && (
            <Subtext color={COLORS.mandy}>
              {errors.youtube.message || 'Please provide a valid source'}
            </Subtext>
          )}
        </div>
        <Gap />
        <div>
          <TextInput
            {...register('instagram', instagramOpts)}
            placeholder="Instagram link"
            style={textInputStyle}
          />
          {errors.instagram && (
            <Subtext color={COLORS.mandy}>
              {errors.instagram.message || 'Please provide a valid source'}
            </Subtext>
          )}
        </div>
        <Gap />
        <Gap />
        <span style={subheaderStyle}>
          Which techniques are being explained?
        </span>
        <Gap />
        <div>
          <OptionSelect
            name="techniques"
            control={control}
            defaultValue={defaultValues.techniques}
            rules={relationRules}
            placeholder="Explained techniques"
            options={getRelationOptions(beatboxState.techniques, 'name')}
            style={selectStyle}
          />
          {errors.techniques && (
            <Subtext color={COLORS.mandy}>
              {errors.techniques.message ||
                'Only existing techniques from selection allowed'}
            </Subtext>
          )}
        </div>
        <Gap />
        <Button
          label={primaryButtonText}
          loadingLabel={primaryButtonLoadingText}
          loading={isSubmitting}
          onMouseUp={handleSubmit(onSubmit)}
          onTouchEnd={handleSubmit(onSubmit)}
          style={primaryButtonStyle}
        />
        <Button
          label={resetButtonText}
          variant={VARIANTS.secondary}
          onMouseUp={handleReset}
          onTouchEnd={handleReset}
          style={resetButtonStyle}
        />
        {!!tutorialId && (
          <>
            <Gap />
            <Gap />
            Actually, I&apos;d like to{' '}
            <b
              role="none"
              onMouseUp={handleShowDeleter}
              onTouchEnd={handleShowDeleter}
              style={showDeleteStyle}
            >
              delete
            </b>{' '}
            this tutorial
          </>
        )}
      </form>
      {!!tutorialId && (
        <Modal
          visible={showDeleter}
          title="Are you sure?"
          hider={() => setShowDeleter(false)}
        >
          <div style={deleterContainerStyle}>
            Deleting tutorial <Code>{tutorialId}</Code> is permanent. Please
            check before proceeding.
          </div>
          <br />
          <Button
            label="Cancel"
            callback={() => setShowDeleter(false)}
            width="48.75%"
            variant={VARIANTS.secondary}
          />
          <Button
            label={deleteButtonText}
            loadingLabel={deleteButtonLoadingText}
            loading={isSubmitting}
            onMouseUp={handleSubmit(onDelete)}
            onTouchEnd={handleSubmit(onDelete)}
            width="48.75%"
            variant={VARIANTS.fail}
            style={deleteButtonStyle}
          />
        </Modal>
      )}
      <Divider />
      <CurrentList plural={CONTENT.tutorials} />
    </>
  );
}

export default TutorialForm;
