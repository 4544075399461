import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import COLORS from '~/constants/colors';
import Button from '~/components/buttons/Button';
import VARIANTS from '~/constants/variants';
import Modal from '~/components/Modal';
import Code from '~/components/flow/Code';
import Break from '~/components/flow/Break';
import Announcement from '~/components/Announcement';
import { useAuth } from '~/providers/Auth';

import { Beat } from '../../../models';
import { deleteBeat } from '../../../services';
import Publish from '../../../components/Publish';
import PATHS, { BEATBLOX_PATH } from '../../../constants/paths';

interface OptionsProps {
  beat: Beat;
  loaded?: Beat;
  disabled?: boolean;
}

function Options({ beat, loaded, disabled }: OptionsProps) {
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string>();
  const { code } = useParams();
  const { state: authState } = useAuth();
  const navigate = useNavigate();
  const cleanLayers = beat.layers.filter((l) => l.pattern !== '');
  const showPublish =
    loaded &&
    cleanLayers.length > 0 &&
    (beat.bpm !== loaded.bpm ||
      cleanLayers.length !== loaded.layers.length ||
      cleanLayers.some((l, idx) => {
        const loadedL = loaded.layers[idx];

        return (
          l.pattern !== loadedL.pattern ||
          (l.group ?? 0) !== (loadedL.group ?? 0)
        );
      }));
  const showDelete =
    loaded && (!loaded.handle || loaded.handle === authState.user?.handle);

  // Handlers
  const hideModal = () => {
    setError(undefined);
    setShowModal(false);
  };
  const handleDelete = async () => {
    if (code) {
      try {
        setIsLoading(true);
        await deleteBeat(code);
        hideModal();
        navigate(BEATBLOX_PATH || PATHS.home);
      } catch {
        setIsLoading(false);
        setError('Deletion failed, please try again later');
      }
    }
  };

  // Styling
  const optionsStyle = {
    marginTop: '0.5em',
  };
  const actionStyle = {
    color: COLORS.tundora,
    borderColor: COLORS.tundora,
    boxShadow: `-0.07em 0.07em ${COLORS.black25}`,
    width: '4.9em',
    ...(showPublish && { marginRight: '0.5em' }),
  };
  const containerStyle = {
    maxWidth: 'min(30em, 82.5vw)',
    padding: '0em 0.5em',
  };
  const modalButtonsStyle = {
    float: 'right' as const,
    marginTop: '-0.5em',
  };
  const deleteStyle = {
    width: '5em',
    margin: '0.5em 0 0',
  };
  const cancelStyle = {
    ...deleteStyle,
    margin: '0 0.5em 0 0',
    color: COLORS.tundora,
    borderColor: COLORS.tundora,
  };

  return !disabled && (showDelete || showPublish) ? (
    <>
      <Break />
      <div style={optionsStyle}>
        {showDelete && (
          <>
            <Button
              variant={VARIANTS.neutral}
              callback={() => setShowModal(true)}
              label="Delete"
              style={actionStyle}
            />
            <Modal visible={showModal} title="Are you sure?" hider={hideModal}>
              {error && (
                <>
                  <Announcement>{error}</Announcement>
                  <Break />
                </>
              )}
              <div style={containerStyle}>
                Deleting beat <Code>{code}</Code> is permanent. Please check
                before proceeding.
              </div>
              <Break />
              <div style={modalButtonsStyle}>
                <Button
                  label="Cancel"
                  variant={VARIANTS.neutral}
                  callback={hideModal}
                  style={cancelStyle}
                />
                <Button
                  label="Delete"
                  loading={isLoading}
                  variant={VARIANTS.fail}
                  callback={handleDelete}
                  style={deleteStyle}
                />
              </div>
            </Modal>
          </>
        )}
        {showPublish && <Publish beat={beat} />}
      </div>
    </>
  ) : (
    <></>
  );
}

export default Options;
