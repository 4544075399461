import React, { ReactElement } from 'react';

import Annotate from '~/components/Annotate';
import COLORS from '~/constants/colors';
import { FONT_WEIGHTS } from '~/constants/typography';

import { Letter } from '../../../../../models';

interface NoticeProps {
  wrong?: Letter;
}

function Notice({ wrong }: NoticeProps): ReactElement {
  const maxValueLength = 6;
  const maxRows = 40;
  const maxCols = 2;
  const truncate = (v) =>
    v.length > maxValueLength ? `${v.slice(0, maxValueLength)}...` : v;

  // Organize
  const error = wrong?.error ?? '';
  const value = truncate(!wrong || wrong.value === ' ' ? '' : wrong.value);

  // Calculate
  const noticeLength = value ? value.length + error.length + 1 : error.length;
  const noticeWidth = Math.min(noticeLength, maxRows);
  const noticeHeight = Math.min(Math.ceil(noticeLength / maxRows), maxCols);
  const containerWidth = `${noticeWidth}ch`;
  const containerHeight = `${noticeHeight + 1.2}em`;
  const contentWidth = `${noticeWidth + (value ? 1 : 0)}ch`;
  const contentHeight = `${noticeHeight + 0.3}em`;

  // Styling
  const contentStyle = {
    width: contentWidth,
    height: contentHeight,
    padding: '0.5em',
  };
  const wrapperStyle = {
    width: contentWidth,
    height: contentHeight,
    color: COLORS.white,
    overflowY: 'scroll' as const,
    textAlign: 'left' as const,
    wordWrap: 'break-word' as const,
  };
  const valueStyle = {
    padding: '0 0.2em',
    color: COLORS.tundora,
    backgroundColor: COLORS.cinderella,
    borderRadius: '0.2em',
    fontWeight: FONT_WEIGHTS.bold,
  };

  return (
    <Annotate
      visible={!!wrong}
      orientation="above"
      containerWidth={containerWidth}
      containerHeight={containerHeight}
      containerBackgroundColor={COLORS.mandy}
      contentStyle={contentStyle}
    >
      <div style={wrapperStyle}>
        {value && (
          <>
            <span style={valueStyle}>{value}</span>{' '}
          </>
        )}
        {error}
      </div>
    </Annotate>
  );
}

export default Notice;
