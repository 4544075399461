import React, { ReactElement, useState, useEffect } from 'react';

import { FONT_SIZES } from '~/constants/typography';
import Gap from '~/components/flow/Gap';
import Break from '~/components/flow/Break';

import { Charts, Sounds } from '../../models';
import { BEAT } from '../../constants/defaults';
import { readSounds } from '../../services';
import Script from '../../components/Script';
import Controls from '../../components/Controls';
import Visualize from '../../components/Visualize';
import Help from '../../components/Help';
import Finish from './components/Finish';

function Write(): ReactElement {
  const [sounds, setSounds] = useState<Sounds>();
  const [beat, setBeat] = useState(BEAT);
  const [charts, setCharts] = useState<Charts>({});
  const [progress, setProgress] = useState(0);
  const [loaded, setLoaded] = useState(BEAT);
  const state = sounds ? 'ready' : 'loading';

  useEffect(() => {
    (async () => setSounds(await readSounds()))();
  }, []);

  // Styling
  const safeWidth = '70ch';
  const writeStyle = {
    minWidth: safeWidth,
    fontSize: FONT_SIZES.text,
  };
  const containerStyle = {
    width: `max(${safeWidth}, 100vw)`,
    textAlign: 'center' as const,
  };

  return (
    <div style={writeStyle}>
      <div style={containerStyle}>
        <Help sounds={sounds} />
        <Break />
        <Visualize
          beat={beat}
          charts={charts}
          state={state}
          progress={progress}
        />
        <Gap />
        <Controls
          beat={beat}
          setBeat={setBeat}
          charts={charts}
          setProgress={setProgress}
          disabled={!sounds}
          loaded={loaded}
        />
        <Break />
        <Script
          sounds={sounds}
          beat={beat}
          setBeat={setBeat}
          setCharts={setCharts}
          disabled={!sounds}
          loaded={loaded}
        />
        <Break />
        <Finish beat={beat} disabled={!sounds} setLoaded={setLoaded} />
      </div>
    </div>
  );
}

export default Write;
