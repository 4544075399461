import React from 'react';

import { FONTS, FONT_WEIGHTS } from '~/constants/typography';
import Header from '~/components/titles/Header';
import Subheader from '~/components/titles/Subheader';
import Gap from '~/components/flow/Gap';
import COLORS from '~/constants/colors';
import Break from '~/components/flow/Break';

import { Beat } from '../../../models';

interface TitleProps {
  beat?: Beat;
}

function Title({ beat }: TitleProps) {
  // Styling
  const titleStyle = {
    display: 'inline-block',
    width: 'min(90vw, 35em)',
    fontFamily: FONTS.typewriter,
    wordWrap: 'break-word' as const,
  };
  const headerStyle = {
    margin: 0,
    textAlign: 'center' as const,
    fontWeight: FONT_WEIGHTS.bold,
    color: COLORS.doveGray,
  };
  const subheaderStyle = {
    textAlign: 'center' as const,
    color: COLORS.doveGray,
  };
  const gapStyle = {
    height: '0.25em',
  };

  return beat?.title || beat?.handle ? (
    <>
      <div style={titleStyle}>
        {beat.title && <Header style={headerStyle}>{beat.title}</Header>}
        {beat.title && beat.handle && <Gap style={gapStyle} />}
        {beat.handle && (
          <Subheader style={subheaderStyle}>
            Scripted by <b>{beat.handle}</b>
          </Subheader>
        )}
      </div>
      <Break />
    </>
  ) : (
    <></>
  );
}

export default Title;
