import urls from '~/urls';

import { BEATBLOX_PATH } from './constants/paths';

export default {
  beats: `${urls.api}/beatbox-beats`,
  beat: (code) => `${urls.api}/beatbox-beats/${code}`,
  clip: (code) => `${urls.api}/beatbox-clips/${code}`,
  read: (code) => `${window.location.origin}${BEATBLOX_PATH}/${code}`,

  // External
  bbxInt: 'https://beatboxinternational.com',
  kindredYouTube: 'https://www.youtube.com/@kindreon',
  kalapuikkoInstagram: 'https://www.instagram.com/nollabbx/',
  justinInstagram: 'https://www.instagram.com/justintansc',
  trickyTelegram: 'https://t.me/victorpapou',
};
