import React, { ReactElement } from 'react';

import COLORS from '~/constants/colors';

import { Letter } from '../../../../../models';

interface StampProps {
  letter: Letter;
}

function Stamp({ letter }: StampProps): ReactElement {
  // Styling
  const stampStyle = {
    display: 'inline-block',
    position: 'relative' as const,
    whiteSpace: 'break-spaces' as const,
  };
  const charStyle = {
    color: letter.error ? COLORS.white : COLORS.black,
  };
  const backgroundStyle = {
    position: 'absolute' as const,
    top: 0,
    left: 0,
    width: '1ch',
    height: '100%',
    backgroundColor: letter.error ? COLORS.mandy : COLORS.transparent,
    zIndex: -2,
  };

  return (
    <span style={stampStyle}>
      <span style={charStyle}>{letter.char}</span>
      <span style={backgroundStyle} />
    </span>
  );
}

export default Stamp;
