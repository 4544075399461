import React, { ReactElement, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import VARIANTS from '~/constants/variants';
import PATHS from '~/constants/paths';
import COLORS from '~/constants/colors';
import { useAuth } from '~/providers/Auth';
import Button from '~/components/buttons/Button';
import TextButton from '~/components/buttons/TextButton';
import Modal from '~/components/Modal';

function Delete(): ReactElement {
  const navigate = useNavigate();
  const { deleteAccount } = useAuth();
  const [showDeleter, setShowDeleter] = useState(false);
  const {
    handleSubmit: handleDeleteSubmit,
    formState: { isSubmitting: isSubmittingDelete },
  } = useForm();
  const deleteButtonText = 'Delete';
  const deleteButtonLoadingText = 'Removing...';

  // Handlers
  const handleShowDeleter = () => setShowDeleter(true);
  const onDelete = () => {
    return new Promise<void>(() => {
      deleteAccount().then(() => {
        setShowDeleter(false);
        navigate(PATHS.root);
      });
    });
  };

  // Styling
  const deleteButtonStyle = {
    marginLeft: '2.5%',
  };
  const showDeleteStyle = {
    color: COLORS.mandy,
  };
  const deleterContainerStyle = {
    padding: '0em 0.5em',
  };
  const showDeleteContainerStyle = {
    textAlign: 'right' as const,
  };

  return (
    <>
      <div style={showDeleteContainerStyle}>
        Actually, I&apos;d like to{' '}
        <TextButton callback={handleShowDeleter} style={showDeleteStyle}>
          delete
        </TextButton>{' '}
        my account
      </div>
      <Modal
        visible={showDeleter}
        title="Are you sure?"
        hider={() => setShowDeleter(false)}
      >
        <div style={deleterContainerStyle}>
          Deleting your account is permanent. Please be certain before
          proceeding.
        </div>
        <br />
        <Button
          label="Cancel"
          callback={() => setShowDeleter(false)}
          width="48.75%"
          variant={VARIANTS.secondary}
        />
        <Button
          label={deleteButtonText}
          loadingLabel={deleteButtonLoadingText}
          loading={isSubmittingDelete}
          onMouseUp={handleDeleteSubmit(onDelete)}
          onTouchEnd={handleDeleteSubmit(onDelete)}
          width="48.75%"
          variant={VARIANTS.fail}
          style={deleteButtonStyle}
        />
      </Modal>
    </>
  );
}

export default Delete;
