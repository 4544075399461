export const MARKS = {
  space: ' ',
  rest: '-',
  sustain: '>',
  hold: '.',
  wildcard: '*',
  missing: '?',
  open: '[',
  close: ']',
  start: '(',
  end: ')',
  caret: '|',
};

export const ERRORS = {
  space: 'Redundant space',
  sustain: "Previous notation can't be sustained",
  hold: "Previous notation can't be held",
  open: 'Opened notation groups must be closed',
  close: 'Closed notation groups must be opened',
  start: 'Started combos must be ended',
  end: 'Ended combos must be started',
  nest: 'Combos must be valid patterns',
  notation: 'Notation not found',
};

export const DESCRIPTIONS = [
  [' ', 'space', 'Separate notation between beats'],
  ['-', 'rest', 'Indicate a pause'],
  ['>', 'sustain', 'Sustain previous notation'],
  ['.', 'hold', 'Sustain but without affecting timing'],
  ['*', 'wildcard', 'Indicate an interchangeable sound'],
  ['[]', 'notate', 'Enclose multi-letter notation'],
  ['()', 'combo', 'Enclose combos'],
];
