import React, {
  ReactElement,
  useState,
  useEffect,
  useLayoutEffect,
} from 'react';
import { useForm } from 'react-hook-form';
import validator from 'validator';
import { useParams, useNavigate } from 'react-router-dom';

import VARIANTS from '~/constants/variants';
import { useAuth } from '~/providers/Auth';
import COLORS from '~/constants/colors';
import { FONT_SIZES } from '~/constants/typography';
import TextInput from '~/components/inputs/TextInput';
import Subtext from '~/components/titles/Subtext';
import Button from '~/components/buttons/Button';
import Gap from '~/components/flow/Gap';
import { getFormErrorHandler } from '~/helpers/journal';
import Announcement from '~/components/Announcement';
import Header from '~/components/titles/Header';
import Divider from '~/components/flow/Divider';
import Link from '~/components/flow/Link';
import Code from '~/components/flow/Code';
import Modal from '~/components/Modal';
import NumberInput from '~/components/inputs/NumberInput';

import {
  DEFAULT_CATEGORY_INFO,
  DEFAULT_CATEGORY_LABEL,
} from '../constants/defaults';
import CONTENT from '../constants/content';
import { useBeatboxContext } from '../providers/BeatboxProvider';
import uris from '../uris';
import { hasPermission } from '../utils';
import CurrentList from './CurrentList';

// TODO: add autofill to category label so people can get a sense if they're adding something already created
function CategoryForm(): ReactElement {
  const [fail, setFail] = useState<string>();
  const [showDeleter, setShowDeleter] = useState(false);
  const { state: authState } = useAuth();
  const {
    state: beatboxState,
    addCategory,
    setCategory,
    listCategories,
    editCategory,
    deleteCategory,
  } = useBeatboxContext();
  const categoryId = Number(useParams().categoryId);

  // Form
  const defaultValues =
    (categoryId && beatboxState.category) || DEFAULT_CATEGORY_INFO;
  const {
    register,
    handleSubmit,
    setError,
    reset,
    formState: { errors, isSubmitting },
  } = useForm({ defaultValues });
  const labelOpts = {
    required: true,
    minLength: 1,
    validate: {
      isAlpha: (label) => validator.isAlpha(label, undefined, { ignore: ' -' }),
      notDefault: (label) =>
        label.toLowerCase() !== DEFAULT_CATEGORY_LABEL.toLowerCase(),
    },
  };
  const positionOpts = {
    required: true,
    valueAsNumber: true,
    validate: (value) => Number.isInteger(value),
  };

  // Rendering
  const primaryButtonText = categoryId ? 'Update' : 'Submit';
  const primaryButtonLoadingText = categoryId ? 'Changing...' : 'Creating...';
  const deleteButtonText = 'Delete';
  const deleteButtonLoadingText = 'Removing...';
  const resetButtonText = categoryId ? 'Reset' : 'Clear';

  // Handlers
  const navigate = useNavigate();
  const onSubmit = (categoryInfo) => {
    return new Promise<void>((resolve) => {
      setFail(undefined);
      (categoryId
        ? editCategory(categoryId, categoryInfo)
        : addCategory(categoryInfo)
      )
        .then(async () => {
          navigate(uris.explore);
        })
        .catch(
          getFormErrorHandler(DEFAULT_CATEGORY_INFO, setError, setFail, resolve)
        );
    });
  };
  const onDelete = () => {
    return new Promise<void>(() => {
      setFail(undefined);
      deleteCategory(categoryId).then(() => {
        setShowDeleter(false);
        navigate(uris.manageCategories);
      });
    });
  };
  const handleReset = () => reset();
  const handleShowDeleter = () => setShowDeleter(true);

  // Styling
  const textInputStyle = {
    width: 'min(320px, 75vw)',
  };
  const numberInputStyle = {
    width: `calc(${textInputStyle.width} - 6px)`,
  };
  const primaryButtonStyle = {
    width: '9em',
    margin: '0.13em 0.5em 0.13em 0',
  };
  const resetButtonStyle = {
    ...primaryButtonStyle,
    margin: '0.13em 0 0.13em 0',
  };
  const deleteButtonStyle = {
    marginLeft: '2.5%',
  };
  const showDeleteStyle = {
    color: COLORS.mandy,
    cursor: 'pointer',
  };
  const deleterContainerStyle = {
    padding: '0em 0.5em',
  };
  const subheaderStyle = {
    fontSize: FONT_SIZES.action,
  };

  // Set category for edit
  useLayoutEffect(() => {
    (async () => {
      if (
        hasPermission(authState, CONTENT.categories) &&
        categoryId &&
        categoryId !== beatboxState.category?.id
      )
        await setCategory(categoryId);
    })();
  }, [categoryId]);
  useEffect(() => reset(defaultValues), [beatboxState.category, categoryId]);

  // List categories
  useEffect(() => {
    (async () => {
      if (
        hasPermission(authState, CONTENT.categories) &&
        (!beatboxState.categories || !beatboxState.category)
      )
        await listCategories();
    })();
  }, [beatboxState.subject, beatboxState.category]);

  return (
    <>
      {!!categoryId && (
        <>
          Go back to <Link href={uris.manageCategories}>Add Category</Link> or
          view in <Link href={uris.explore}>Explore</Link>
        </>
      )}
      <Header>
        {categoryId ? 'Edit' : 'Add'} Category
        {!!categoryId && (
          <>
            {' '}
            <Code>{categoryId}</Code>
          </>
        )}
      </Header>
      {fail && (
        <>
          <Announcement>{fail}</Announcement>
          <Gap />
        </>
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div>
          <TextInput
            {...register('label', labelOpts)}
            placeholder="Label"
            style={textInputStyle}
          />
          {errors.label && (
            <Subtext color={COLORS.mandy}>
              {errors.label.message ||
                (errors.label.type === 'notDefault' &&
                  'Label already being used for default') ||
                'Label with only letters and spaces required'}
            </Subtext>
          )}
        </div>
        <Gap />
        <Gap />
        <span style={subheaderStyle}>
          Where should this category be when sorted?
        </span>
        <Gap />
        <div>
          <NumberInput
            {...register('position', positionOpts)}
            placeholder="Position"
            style={numberInputStyle}
          />
          {errors.position && (
            <Subtext color={COLORS.mandy}>
              {errors.position.message || 'Integer position required'}
            </Subtext>
          )}
        </div>
        <Gap />
        <Button
          label={primaryButtonText}
          loadingLabel={primaryButtonLoadingText}
          loading={isSubmitting}
          onMouseUp={handleSubmit(onSubmit)}
          onTouchEnd={handleSubmit(onSubmit)}
          style={primaryButtonStyle}
        />
        <Button
          label={resetButtonText}
          variant={VARIANTS.secondary}
          onMouseUp={handleReset}
          onTouchEnd={handleReset}
          style={resetButtonStyle}
        />
        {!!categoryId && (
          <>
            <Gap />
            <Gap />
            Actually, I&apos;d like to{' '}
            <b
              role="none"
              onMouseUp={handleShowDeleter}
              onTouchEnd={handleShowDeleter}
              style={showDeleteStyle}
            >
              delete
            </b>{' '}
            this category
          </>
        )}
      </form>
      {!!categoryId && (
        <Modal
          visible={showDeleter}
          title="Are you sure?"
          hider={() => setShowDeleter(false)}
        >
          <div style={deleterContainerStyle}>
            Deleting category <Code>{categoryId}</Code> is permanent. Please
            check before proceeding.
          </div>
          <br />
          <Button
            label="Cancel"
            callback={() => setShowDeleter(false)}
            width="48.75%"
            variant={VARIANTS.secondary}
          />
          <Button
            label={deleteButtonText}
            loadingLabel={deleteButtonLoadingText}
            loading={isSubmitting}
            onMouseUp={handleSubmit(onDelete)}
            onTouchEnd={handleSubmit(onDelete)}
            width="48.75%"
            variant={VARIANTS.fail}
            style={deleteButtonStyle}
          />
        </Modal>
      )}
      <Divider />
      <CurrentList plural={CONTENT.categories} />
    </>
  );
}

export default CategoryForm;
