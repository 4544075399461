import React, { ReactElement } from 'react';

import Header from '~/components/titles/Header';
import Subheader from '~/components/titles/Subheader';
import Subtext from '~/components/titles/Subtext';
import Prompt from '~/components/titles/Prompt';
import Code from '~/components/flow/Code';
import Divider from '~/components/flow/Divider';
import Link from '~/components/flow/Link';
import COLORS from '~/constants/colors';
import Break from '~/components/flow/Break';
import Gap from '~/components/flow/Gap';
import { FONT_SIZES, FONT_WEIGHTS } from '~/constants/typography';

import urls from '../../urls';
import Feature from './components/Feature';
import communicate from './assets/communicate.png';
import conceptualize from './assets/conceptualize.png';
import compose from './assets/compose.png';
import professorOak from './assets/professor-oak.gif';

function About(): ReactElement {
  const random = Math.floor(Math.random() * 420);

  // Styling
  const aboutStyle = {
    display: 'inline-block',
    width: 'min(30em, 82.5vw)',
    color: COLORS.tundora,
  };
  const headerStyle = {
    margin: 0,
    fontWeight: FONT_WEIGHTS.thin,
  };
  const topicStyle = {
    color: COLORS.platinum,
  };
  const wrapperStyle = {
    textAlign: 'center' as const,
  };
  const containerStyle = {
    display: 'inline-block',
    textAlign: 'left' as const,
  };
  const oakStyle = {
    display: 'inline-block',
    width: '23em',
    height: '16.5em',
    marginBottom: '0.55em',
    background: `top -4.6em left 50%/23em no-repeat url(${professorOak})`,
  };
  const subtextStyle = {
    color: COLORS.black,
  };
  const spanStyle = {
    float: 'right' as const,
    fontSize: FONT_SIZES.subtext,
  };
  const subliminalStyle = {
    color: COLORS.alabaster,
  };

  return (
    <div style={aboutStyle}>
      {random === 69 && (
        <div style={wrapperStyle}>
          <Header style={wrapperStyle}>
            Wait, how did you find this page?
          </Header>
          <Subtext style={subtextStyle}>
            I&apos;m certain I deleted the code for this...
          </Subtext>
          <Break />
          <Break />
          <Prompt style={wrapperStyle}>
            Fine, since you&apos;re already here, let&apos;s have a little chat.
          </Prompt>
          <Break />
          <div style={containerStyle}>
            Beatbox used to be this thing people did to try and{' '}
            <b>find their place in the world</b>. Not many people could do it so
            if you could beatbox, you really felt you{' '}
            <b>provided something unique to others</b>.
            <Gap />
            <span style={spanStyle}>
              ...now that I think about it, it was a lot like grassroots
              Melee...
            </span>
            <Break />
            <Break />
            Today, beatbox has become something with{' '}
            <b>quite a few conflicting interests</b>.
            <Break />
            <div style={wrapperStyle}>
              <Code>[money, views, clout, drama, people&apos;s egos, ...]</Code>
            </div>
            <Break />
            These are the growing pains of any maturing subject, but one thing
            hasn&apos;t changed. <b>Beatboxing is awesome</b>. So if you stay
            true to yourself, certainly you too can find your place.
          </div>
          <Break />
          <div style={oakStyle} />
          <div style={containerStyle}>
            <Subtext style={subliminalStyle}>
              Now that the Disney speech is over, don&apos;t forget to subscribe
              to my YouTube and follow me on Twitter. I need funds for my
              backyard turkey racing league, so stop highlighting this
              subliminal message and get back to learning sick beats on
              Beatblox.
            </Subtext>
          </div>
        </div>
      )}
      {random !== 69 && (
        <>
          <Header style={headerStyle}>
            Building Blocks <span style={topicStyle}>for</span> Beatbox
          </Header>
          <Divider />
          <Subheader>
            Beatblox aims to develop beatbox ideas into beatbox understanding as
            effectively as possible.
          </Subheader>
          <Break />
          <div style={wrapperStyle}>
            <div style={containerStyle}>
              <Feature
                src={communicate}
                name="Communicate"
                caption="with clarity"
                text="See and hear the patterns you write"
              />
              <Break />
              <Feature
                src={conceptualize}
                name="Conceptualize"
                caption="with confidence"
                text="Learn precisely without studying theory"
              />
              <Break />
              <Feature
                src={compose}
                name="Compose"
                caption="with complexity"
                text="Express beats at the highest level"
              />
            </div>
          </div>
          <Break />
          <Subheader>
            Launched in October of 2023 by{' '}
            <Link href={urls.justinInstagram}>Justin</Link>,{' '}
            <Link href={urls.kalapuikkoInstagram}>Kalapuikko</Link>,{' '}
            <Link href={urls.kindredYouTube}>Kindred</Link>, and{' '}
            <Link href={urls.trickyTelegram}>Tricky</Link> to help beatboxers
            along their journey of turning sounds they&apos;ve learned into
            music they enjoy.
          </Subheader>
        </>
      )}
    </div>
  );
}

export default About;
