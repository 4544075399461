import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import Modal from '~/components/Modal';
import TextInput from '~/components/inputs/TextInput';
import COLORS from '~/constants/colors';
import { FONT_WEIGHTS, FONT_SIZES } from '~/constants/typography';
import Break from '~/components/flow/Break';
import Gap from '~/components/flow/Gap';
import Button from '~/components/buttons/Button';
import Header from '~/components/titles/Header';
import Subtext from '~/components/titles/Subtext';
import Divider from '~/components/flow/Divider';
import VARIANTS from '~/constants/variants';
import Announcement from '~/components/Announcement';
import { getFormErrorHandler } from '~/helpers/journal';

import { Beat } from '../models';
import uris from '../uris';
import { createBeat } from '../services';
import { isYouTubeLink, isBshLink } from '../helpers';

const DEFAULT_INFO = {
  title: undefined,
  demo: undefined,
};

interface PublishProps {
  beat: Beat;
  disabled?: boolean;
}

function Publish({ beat, disabled }: PublishProps) {
  const [visible, setVisible] = useState(false);
  const [fail, setFail] = useState<string | undefined>();
  const navigate = useNavigate();

  // Form
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors, isSubmitting },
  } = useForm({ defaultValues: DEFAULT_INFO });
  const demoOpts = {
    validate: (link) => !link || isYouTubeLink(link) || isBshLink(link),
  };

  // Handlers
  const uploadBeat = (details) => {
    return new Promise<void>((resolve) => {
      if (beat.accepted) {
        const finalBeat = {
          ...beat,
          title: details.title,
          demo: details.demo,
        };

        setFail(undefined);
        createBeat(finalBeat)
          .then((code) => navigate(uris.read(code)))
          .catch(getFormErrorHandler(DEFAULT_INFO, setError, setFail, resolve));
      } else setFail('Please resolve errors in your beat before uploading');
    });
  };

  // Styling
  const modalStyle = {
    width: 'min(95vw, 30em)',
    padding: '1em',
    boxSizing: 'border-box' as const,
    fontSize: FONT_SIZES.action,
  };
  const buttonStyle = {
    width: '4.9em',
    color: COLORS.tundora,
    borderColor: COLORS.tundora,
    boxShadow: `-0.07em 0.07em ${COLORS.black25}`,
  };
  const disabledButtonStyle = {
    backgroundColor: COLORS.gallery,
    borderColor: COLORS.gallery,
  };
  const disabledButtonLabelStyle = {
    color: COLORS.gray,
  };
  const headerStyle = {
    margin: 0,
    fontWeight: FONT_WEIGHTS.thin,
  };
  const dividerStyle = {
    margin: '0.75em 0 1em',
  };
  const inputStyle = {
    width: '100%',
    boxSizing: 'border-box' as const,
  };
  const modalButtonStyle = {
    float: 'right' as const,
    width: '5em',
    marginLeft: '0.75em',
  };
  const cancelButtonStyle = {
    ...modalButtonStyle,
    color: COLORS.tundora,
    borderColor: COLORS.tundora,
  };

  return (
    <>
      <Button
        label="Publish"
        variant={VARIANTS.neutral}
        callback={() => setVisible(true)}
        disabled={disabled || !beat.accepted}
        style={buttonStyle}
        disabledStyle={disabledButtonStyle}
        disabledLabelStyle={disabledButtonLabelStyle}
      />
      <Modal
        visible={visible}
        hider={() => setVisible(false)}
        style={modalStyle}
      >
        <Header style={headerStyle}>Finishing Details</Header>
        <Divider style={dividerStyle} />
        {fail && (
          <>
            <Announcement>{fail}</Announcement>
            <Break />
          </>
        )}
        <form>
          Do you want to name this beat?
          <Gap />
          <TextInput
            {...register('title')}
            placeholder="Optional title"
            maxLength={280}
            style={inputStyle}
          />
          {errors.title && (
            <Subtext color={COLORS.mandy}>
              {errors.title.message || 'Inappropriate title'}
            </Subtext>
          )}
          <Break />
          Include link to a demonstration?
          <Gap />
          <TextInput
            {...register('demo', demoOpts)}
            placeholder="YouTube or Beatbox Share"
            maxLength={280}
            style={inputStyle}
          />
          {errors.demo && (
            <Subtext color={COLORS.mandy}>
              {errors.demo.message || 'Invalid YouTube or Beatbox Share link'}
            </Subtext>
          )}
          <Break />
          <Button
            label="Upload"
            loadingLabel="..."
            loading={isSubmitting}
            onMouseUp={handleSubmit(uploadBeat)}
            onTouchEnd={handleSubmit(uploadBeat)}
            style={modalButtonStyle}
          />
          <Button
            label="Cancel"
            variant={VARIANTS.secondary}
            callback={() => setVisible(false)}
            style={cancelButtonStyle}
          />
        </form>
      </Modal>
    </>
  );
}

export default Publish;
