import React, {
  ReactElement,
  PropsWithChildren,
  useRef,
  useEffect,
  CSSProperties,
} from 'react';

import COLORS from '~/constants/colors';
import Triangle from '~/components/shapes/Triangle';

import { getTriangleDirection, getOrientationStyles } from './utils';
import { Orientation } from './models';

interface AnnotateProps {
  visible: boolean;
  hider?: (event: Event) => void;
  hiderBoundary?: 'parent' | 'container';
  shade?: boolean;
  orientation?: Orientation;
  skew?: number;
  containerWidth?: string;
  containerHeight?: string;
  shadeBackgroundColor?: string;
  containerBackgroundColor?: string;
  style?: CSSProperties;
  contentStyle?: CSSProperties;
  childrenStyle?: CSSProperties;
}

function Annotate({
  visible = false,
  hider,
  hiderBoundary = 'parent',
  shade = false,
  orientation = 'below',
  skew = 0,
  containerWidth = '6em',
  containerHeight = '3em',
  shadeBackgroundColor = COLORS.black25,
  containerBackgroundColor = COLORS.white,
  style,
  contentStyle,
  childrenStyle,
  children,
}: PropsWithChildren<AnnotateProps>): ReactElement {
  const annotateRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  // Handlers
  const handleHideAnnotate = (event) => {
    if (annotateRef.current && containerRef.current) {
      const { parentElement } = annotateRef.current;
      const outsideParent =
        parentElement && !parentElement.contains(event.target);
      const outsideContainer = !containerRef.current.contains(event.target);
      const isOutside =
        hiderBoundary === 'parent' ? outsideParent : outsideContainer;
      const isDisplayed = annotateRef.current.style.display !== 'none';

      if (isOutside && isDisplayed) hider?.(event);
    }
  };

  // Styling
  const base = '0.8em';
  const ratio = 0.8;
  const triangleAltitude = `${base} * ${ratio}`;
  const orientationStyles = getOrientationStyles(skew, triangleAltitude);
  const annotateStyle = {
    display: visible ? 'flex' : 'none',
    zIndex: 10,
  };
  const shadeStyle = {
    // TODO: this doesn't fix scrolling with 2 fingers on mobile but does prevent bottom bar from pushing background around
    position: 'fixed' as const, // TODO: isMobile ? ('absolute' as const) : ('fixed' as const),
    top: 0, // TODO: isMobile ? `${window.scrollY - 1}px` : 0,
    left: 0,
    width: '100vw',
    height: '100vh', // TODO: 'calc(100vh + 2px)',
    backgroundColor: shadeBackgroundColor,
    zIndex: 0,
  };
  const containerStyle = {
    position: 'absolute' as const,
    width: containerWidth,
    height: containerHeight,
    zIndex: 1,
    ...orientationStyles[orientation].container,
    ...style,
  };
  const triangleStyle = {
    position: 'absolute' as const,
    zIndex: 1,
    ...orientationStyles[orientation].triangle,
  };
  const finalContentStyle = {
    display: 'table',
    position: 'absolute' as const,
    width: '100%',
    height: '100%',
    color: COLORS.mineShaft,
    backgroundColor: containerBackgroundColor,
    borderRadius: '0.3em',
    boxShadow: `-0.05em 0.05em 0.5em ${COLORS.black25}`,
    textAlign: 'center' as const,
    overflow: 'hidden',
    zIndex: 0,
    ...orientationStyles[orientation].content,
    ...contentStyle,
  };
  const finalChildrenStyle = {
    display: 'table-cell',
    verticalAlign: 'middle',
    ...childrenStyle,
  };

  useEffect(() => {
    document.addEventListener('click', handleHideAnnotate);

    return () => document.removeEventListener('click', handleHideAnnotate);
  });

  return (
    <div style={annotateStyle} ref={annotateRef}>
      {shade && <div style={shadeStyle} />}
      <div style={containerStyle} ref={containerRef}>
        <Triangle
          base={base}
          ratio={ratio}
          direction={getTriangleDirection(orientation)}
          fill={containerBackgroundColor}
          style={triangleStyle}
        />
        <div style={finalContentStyle}>
          <div style={finalChildrenStyle}>{children}</div>
        </div>
      </div>
    </div>
  );
}

export default Annotate;
