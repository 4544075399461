import React, { Dispatch, SetStateAction, ReactElement } from 'react';

import COLORS from '~/constants/colors';
import Modal from '~/components/Modal';
import Button from '~/components/buttons/Button';
import VARIANTS from '~/constants/variants';
import Link from '~/components/flow/Link';
import PATHS from '~/constants/paths';

import { maxLives } from '../constants/game';
import { SetState } from '../models';
import { closeEnding } from '../helpers';

interface EndingProps {
  setState: SetState;
  showEnding: boolean;
  setShowEnding: Dispatch<SetStateAction<boolean>>;
  points: number;
  maxPoints: number;
  playtime: string;
  mistakes: string[];
}

function Ending({
  setState,
  showEnding,
  setShowEnding,
  points,
  maxPoints,
  playtime,
  mistakes,
}: EndingProps): ReactElement {
  const clickHandler = () => closeEnding(setState, setShowEnding);
  const percent = points / maxPoints;
  const getKey = (index) => `KanaMatchEndingMissedList${index}`;
  const missedList = mistakes.map((mistake, index) => (
    <span key={getKey(index)}>
      <b>{mistake}</b>
      {index !== mistakes.length - 1 && ','}{' '}
    </span>
  ));
  let title = 'Perfect Run';
  let buttonVariant = VARIANTS.success;

  if (points <= maxPoints - maxLives) {
    title = 'You Lose...';
    buttonVariant = VARIANTS.fail;
  } else if (points < maxPoints) {
    title = 'Just Survived';
    buttonVariant = VARIANTS.warning;
  }

  // Styling
  const containerStyle = {
    padding: '0em 0.5em',
  };
  const linkStyle = {
    color: COLORS.cornflower,
    textDecoration: 'none' as const,
  };
  const pointsStyle = {
    color: COLORS.salem,
  };
  const maxPointsStyle = {
    color: COLORS.salem,
  };
  const playtimeStyle = {
    color: COLORS.indigo,
    fontFamily: 'Roboto, monospace',
  };
  const missedStyle = {
    display: 'inline-block',
  };

  return (
    <Modal title={title} visible={showEnding} hider={clickHandler}>
      <div style={containerStyle}>
        <div>
          You scored <b style={pointsStyle}>{points}</b> out of{' '}
          <b style={maxPointsStyle}>{maxPoints}</b> problems in{' '}
          <b style={playtimeStyle}>{playtime}</b> time.
          {mistakes.length > 0 && (
            <>
              {' '}
              Here&apos;s the set of mistakes you made:{' '}
              <div style={missedStyle}>&#123; {missedList} &#125;</div>.
            </>
          )}
        </div>
        <div>
          {percent <= 0.5 && (
            <p>
              Try brushing up on{' '}
              <Link
                href="https://wikipedia.org/wiki/Hiragana"
                style={linkStyle}
              >
                hiragana
              </Link>{' '}
              and{' '}
              <Link
                href="https://wikipedia.org/wiki/Katakana"
                style={linkStyle}
              >
                katakana
              </Link>{' '}
              for your next run!
            </p>
          )}
          {percent > 0.5 && percent <= 0.8 && (
            <p>Keep up the grind, you&apos;re doing great! 🙏❤️</p>
          )}
          {percent > 0.8 && percent < 1 && (
            <p>
              You were almost perfect! The last bit&apos;s the hardest but keep
              fighting! 🔥💪
            </p>
          )}
          {points === maxPoints && (
            <p>
              Hope the EXP helped you level up! Are you ready for the{' '}
              <Link href={PATHS.flowGo}>next level</Link>? 💯🎉
            </p>
          )}
        </div>
      </div>
      <Button
        label="Play Again"
        variant={buttonVariant}
        width="100%"
        callback={clickHandler}
      />
    </Modal>
  );
}

export default Ending;
