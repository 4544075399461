import { Sounds, Sentence, Chart, Layers } from '../../../models';
import { extractSounds } from '../../../helpers';
import getPatternParser from './pattern';

type Parser = (
  layers: Layers,
  muted?: boolean[]
) => {
  accepted: boolean;
  sentences: Sentence[];
  chart: Chart;
  sounds: Sounds;
};

const getParser = (sounds: Sounds, bpm: number): Parser => {
  const patternParser = getPatternParser(sounds, bpm);
  const parser: Parser = (layers, muted?) => {
    const layered = layers.reduce(
      (acc, l, idx) => {
        const res = patternParser(l.pattern, acc.tracks);
        const { tracks, sentence, chart } = res;
        const accepted = !res.sentence.some((w) => !!w.error);

        return {
          accepted: acc.accepted && accepted,
          tracks,
          sentences: [...acc.sentences, sentence],
          chart: [...acc.chart, ...(muted?.[idx] ? [] : chart)],
        };
      },
      {
        accepted: true,
        tracks: {},
        sentences: [] as Sentence[],
        chart: [] as Chart,
      }
    ); // Note `reduce` is sequential in JavaScript

    return {
      ...layered,
      accepted: layered.accepted && layers.length > 0,
      sounds: Object.values(extractSounds(layered.chart)),
    };
  };

  return parser;
};

export default getParser;
