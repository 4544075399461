import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';

import { stopEventEffects } from '~/utils';

import { Beat, Sounds, Charts, Sentence } from '../../models';
import { cleanCarets } from '../../helpers';
import { insertCaret } from './helpers';
import getParser from './parsers/groups';
import Line from './components/Line';
import Add from './components/Add';

interface ScriptProps {
  sounds?: Sounds;
  beat: Beat;
  setBeat: Dispatch<SetStateAction<Beat>>;
  setCharts: Dispatch<SetStateAction<Charts>>;
  disabled?: boolean;
  loaded?: Beat;
}

function Script({
  sounds,
  beat,
  setBeat,
  setCharts,
  disabled,
  loaded,
}: ScriptProps) {
  const [focusIdx, setFocusIdx] = useState(-1);
  const [patterns, setPatterns] = useState(['']);
  const [groups, setGroups] = useState([0]);
  const [sentences, setSentences] = useState<Sentence[]>([]);
  const [muted, setMuted] = useState([false]);
  const parser = sounds && getParser(sounds, beat.bpm);
  const linesRef = useRef<HTMLDivElement>(null);
  const getLineKey = (idx) => `BeatbloxScriptLine${idx}`;

  useEffect(() => {
    if (parser) {
      const layers = patterns.map((p, idx) => ({
        pattern: p,
        group: groups[idx],
      }));
      const res = parser(layers, muted);
      const cleanLayers = layers
        .map((l) => ({ ...l, pattern: cleanCarets(l.pattern) }))
        .filter((l) => l.pattern !== '');
      const accepted =
        res.accepted &&
        cleanLayers.length > 0 &&
        cleanLayers.every((l) => !l.pattern.endsWith(' '));

      setSentences(res.sentences);
      setCharts(res.charts);
      setBeat({ ...beat, accepted, layers: cleanLayers, sounds: res.sounds });
    } else setCharts({ [groups[0]]: [] }); // Needed for allowing group selection while loading
  }, [patterns, groups, muted]);

  // Management
  const getLines = () => Array.from(linesRef.current?.children ?? []);
  const focusLine = (idx) =>
    getLines()[idx]?.getElementsByTagName('textarea')[0]?.focus();
  const addLine = () => {
    setPatterns([...patterns, '']);
    setGroups([...groups, 0]);
    setMuted([...muted, false]);
    setFocusIdx(patterns.length);
  };
  const getRemoveLine = (idx) => () => {
    setPatterns(patterns.filter((p, pIdx) => pIdx !== idx));
    setGroups(groups.filter((g, gIdx) => gIdx !== idx));
    setMuted(muted.filter((m, mIdx) => mIdx !== idx));
    setFocusIdx(Math.max(idx - 1, 0));
  };

  useEffect(() => {
    if (focusIdx >= 0) {
      focusLine(focusIdx);
      setFocusIdx(-1);
    }
  }, [focusIdx]);

  // Selection
  const handleSelection = (event) => {
    const newPatterns = Array.from(linesRef.current?.children ?? []).map(
      (e) => {
        const textarea = e.getElementsByTagName('textarea')[0];
        let newPattern = textarea?.value ?? '';

        if (textarea === document.activeElement) {
          const { selectionStart, selectionEnd } =
            document.activeElement as HTMLTextAreaElement;

          newPattern = insertCaret(newPattern, selectionStart);
          if (selectionStart !== selectionEnd)
            newPattern = insertCaret(newPattern, selectionEnd + 1);
        }

        return newPattern;
      }
    );

    stopEventEffects(event);
    setPatterns(newPatterns);
  };

  useEffect(() => {
    (async () => {
      document.addEventListener('selectionchange', handleSelection);

      return () =>
        document.removeEventListener('selectionchange', handleSelection);
    })();
  }, []);

  // Loaded
  useEffect(() => {
    if (loaded) {
      setPatterns(loaded.layers.map((l) => l.pattern));
      setGroups(loaded.layers.map((l) => l.group ?? 0));
    }
  }, [loaded]);

  return (
    <div>
      <div ref={linesRef}>
        {patterns.map((p, idx) => (
          <Line
            key={getLineKey(idx)}
            index={idx}
            sounds={sounds}
            sentence={sentences[idx]}
            patterns={patterns}
            setPatterns={setPatterns}
            groups={groups}
            setGroups={setGroups}
            muted={muted}
            setMuted={setMuted}
            disabled={disabled}
            addLine={addLine}
            removeLine={getRemoveLine(idx)}
          />
        ))}
      </div>
      <Add addLine={addLine} disabled={disabled} />
    </div>
  );
}

export default Script;
