import COLORS from '~/constants/colors';

import { Sound, Beat } from '../models';
import { MARKS } from './language';
import { MIME_TYPES } from './media';
import wildcard from '../assets/wildcard.flac';
import missing from '../assets/missing.flac';

export const TEMPO_NUMERATOR = 60000;

export const GROUPS = [
  COLORS.jaffa,
  COLORS.kournikova,
  COLORS.wildWillow,
  COLORS.fountainBlue,
  COLORS.coldPurple,
];

export const BEAT: Beat = {
  id: -1,
  loops: 1,
  bpm: 60,
  layers: [{ pattern: '' }],
  sounds: [],
};

export const REST: Sound = {
  id: -1,
  name: 'Rest',
  notation: MARKS.rest,
  sample: {
    id: -1,
    recording: {
      mime: '',
      url: '',
    },
  },
};

export const WILDCARD: Sound = {
  id: -2,
  name: 'Wildcard',
  notation: MARKS.wildcard,
  sample: {
    id: -2,
    recording: {
      mime: MIME_TYPES.flac,
      url: wildcard,
    },
  },
};

export const MISSING: Sound = {
  id: -3,
  name: 'Missing',
  notation: MARKS.missing,
  sample: {
    id: -3,
    recording: {
      mime: MIME_TYPES.flac,
      url: missing,
    },
  },
};

export const CARET: Sound = {
  id: -4,
  name: 'Caret',
  notation: MARKS.caret,
  sample: {
    id: -4,
    recording: {
      mime: '',
      url: '',
    },
  },
};
