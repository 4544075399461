import React, { useState } from 'react';

import { stopEventEffects } from '~/utils';
import Annotate from '~/components/Annotate';
import COLORS from '~/constants/colors';

import { GROUPS } from '../../../constants/defaults';

interface GrouperProps {
  index: number;
  groups: number[];
  setGroups: (groups: number[]) => void;
}

function Grouper({ index, groups, setGroups }: GrouperProps) {
  const [visible, setVisible] = useState(false);
  const getKey = (idx) => `BeatbloxScriptGrouperOption${idx}`;

  // Handlers
  const togglePicker = (event) => {
    stopEventEffects(event);
    setVisible(!visible);
  };
  const getPickGroup = (key) => (event) => {
    const newGroups = [...groups];

    newGroups[index] = Number(key);
    stopEventEffects(event);
    setGroups(newGroups);
    setVisible(false);
  };

  // Calculate
  const totalGroups = GROUPS.length;
  const multiplier = 2;
  const optionHeight = `${multiplier}em`;
  const width = '2em';
  const height = `${totalGroups * multiplier}em`;
  const containerWidth = `calc(${width})`;
  const containerHeight = `${totalGroups * multiplier}em`;

  // Styling
  const grouperStyle = {
    display: 'inline-block',
    position: 'relative' as const,
    width: '1.3em',
    height: '1.3em',
    marginRight: '0.5em',
    verticalAlign: 'middle',
    borderRadius: '0.65em',
    backgroundColor: GROUPS[groups[index] ?? 0],
    boxShadow: `-0.07em 0.07em ${COLORS.black50}`,
    cursor: 'pointer',
  };
  const contentStyle = {
    width,
    height,
  };
  const wrapperStyle = {
    width,
    height,
    lineHeight: optionHeight,
  };
  const optionStyle = {
    width,
    height: optionHeight,
  };
  const getGroupStyle = (color) => ({
    display: 'inline-block',
    width: '1.5ch',
    height: '1.5ch',
    borderRadius: '0.5ch',
    backgroundColor: color,
    cursor: 'pointer',
  });

  return (
    <div
      role="button"
      tabIndex={-1}
      onMouseUp={togglePicker}
      onTouchEnd={togglePicker}
      style={grouperStyle}
    >
      <Annotate
        visible={visible}
        hider={() => setVisible(false)}
        orientation="left"
        containerWidth={containerWidth}
        containerHeight={containerHeight}
        containerBackgroundColor={COLORS.gallery}
        contentStyle={contentStyle}
      >
        <div style={wrapperStyle}>
          {Object.keys(GROUPS).map((k, idx) => (
            <div
              key={getKey(idx)}
              role="button"
              tabIndex={-1}
              onMouseUp={getPickGroup(k)}
              onTouchEnd={getPickGroup(k)}
              style={optionStyle}
            >
              <div style={getGroupStyle(GROUPS[k])} />
            </div>
          ))}
        </div>
      </Annotate>
    </div>
  );
}

export default Grouper;
