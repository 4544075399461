import React from 'react';

import { stopEventEffects } from '~/utils';
import COLORS from '~/constants/colors';
import { FONT_SIZES } from '~/constants/typography';
import Button from '~/components/buttons/Button';
import Gap from '~/components/flow/Gap';

interface AddProps {
  addLine: () => void;
  disabled?: boolean;
}

function Add({ addLine, disabled }: AddProps) {
  // Handlers
  const callback = (event) => {
    stopEventEffects(event);
    addLine();
  };

  // Styling
  const addStyle = {
    padding: '0.3em',
    backgroundColor: COLORS.doveGray,
    borderColor: COLORS.doveGray,
    borderRadius: '0.3em',
    fontSize: FONT_SIZES.text,
    boxShadow: `-0.07em 0.07em ${COLORS.black25}`,
    verticalAlign: 'middle',
  };

  return disabled ? (
    <></>
  ) : (
    <>
      <Gap />
      <Button label="Add Layer" callback={callback} style={addStyle} />
    </>
  );
}

export default Add;
