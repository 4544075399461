import { Sound, Sounds, Tracks, Sentence, Chart } from '../../../models';
import {
  TEMPO_NUMERATOR,
  REST,
  WILDCARD,
  MISSING,
  CARET,
} from '../../../constants/defaults';
import { MARKS, ERRORS } from '../../../constants/language';
import { cleanCarets } from '../../../helpers';

type Parser = (
  pattern: string,
  tracks?: Tracks,
  mIdx?: number,
  cIdx?: number,
  prev?: string | Sound
) => {
  mLen: number;
  tracks: Tracks;
  sentence: Sentence;
  chart: Chart;
  end?: number;
};

const getParser = (sounds: Sounds, bpm: number): Parser => {
  const parser: Parser = (pattern, tracks = {}, mIdx = 0, cIdx = 0, prev?) => {
    const getSound = (p) => {
      const cleaned = cleanCarets(p);

      return sounds.find((s) => s.notation === cleaned);
    };
    const getTrack = (s = MISSING, ts = tracks) =>
      ts[s.id] ?? Math.max(...Object.values(ts), -1) + 1;
    const getTracks = (s = MISSING, ts = tracks) => ({
      ...ts,
      [s.id]: getTrack(s, ts),
    });
    const getSentence = (r, value, error?) => [{ value, error }, ...r.sentence];
    const getChart = (r, ts, sound = MISSING, start, end?, closeIdx?) => {
      const caretIdx = pattern.indexOf(MARKS.caret);

      return [
        { track: ts[sound.id], start, end, sound },
        ...(closeIdx && caretIdx > -1 && caretIdx < closeIdx
          ? [{ track: ts[CARET.id], start, end, sound: CARET }]
          : []),
        ...r.chart,
      ];
    };
    const getScaleNote = (ts, start, scale) => (n) => ({
      ...n,
      track: ts[n.sound.id],
      start: n.start * scale + start,
      ...(n.end && { end: n.end * scale + start }),
    });

    // Base case
    if (!pattern) return { mLen: cIdx, tracks, sentence: [], chart: [] };

    // Recursion
    let pNext = pattern.slice(1);
    const mDur = TEMPO_NUMERATOR / Math.max(bpm, 1);

    if (pattern[0] === MARKS.space) {
      const resNext = parser(pNext, tracks, mIdx + 1, 0, prev);
      const accept = cIdx > 0 && pattern.length > 1;

      return {
        mLen: 0,
        tracks: resNext.tracks,
        sentence: getSentence(resNext, MARKS.space, !accept && ERRORS.space),
        chart: resNext.chart,
        end: resNext.end,
      };
    } else if (pattern[0] === MARKS.rest) {
      const resNext = parser(pNext, tracks, mIdx, cIdx + 1, MARKS.rest);
      const mLen = Math.max(cIdx + 1, resNext.mLen);
      const start = mIdx * mDur + cIdx * (mDur / mLen);

      return {
        mLen,
        tracks: resNext.tracks, // TODO: may want to add rest to `tracks`
        sentence: getSentence(resNext, MARKS.rest),
        chart: getChart(resNext, tracks, REST, start), // TODO: may want to calculate `tracks` with rest
      };
    } else if (pattern[0] === MARKS.sustain) {
      const resNext = parser(pNext, tracks, mIdx, cIdx + 1, MARKS.sustain);
      const accept = prev && prev !== MARKS.rest && prev !== MARKS.hold;
      const mLen = Math.max(cIdx + 1, resNext.mLen);
      const end = mIdx * mDur + (cIdx + 1) * (mDur / mLen);

      return {
        mLen,
        tracks: resNext.tracks,
        sentence: getSentence(
          resNext,
          MARKS.sustain,
          !accept && ERRORS.sustain
        ),
        chart: resNext.chart,
        end: Math.max(end, resNext.end ?? -1),
      };
    } else if (pattern[0] === MARKS.hold) {
      const resNext = parser(pNext, tracks, mIdx, cIdx, MARKS.hold);
      const accept =
        cIdx > 0 &&
        prev !== MARKS.rest &&
        prev !== MARKS.sustain &&
        prev !== MARKS.hold;
      const mLen = Math.max(cIdx, resNext.mLen);

      return {
        mLen,
        tracks: resNext.tracks,
        sentence: getSentence(resNext, MARKS.hold, !accept && ERRORS.hold),
        chart: resNext.chart,
        end: mIdx * mDur + (mLen > 0 ? cIdx * (mDur / mLen) : 0),
      };
    } else if (pattern[0] === MARKS.wildcard) {
      const ts = getTracks(WILDCARD);
      const resNext = parser(pNext, ts, mIdx, cIdx + 1, MARKS.wildcard);
      const mLen = Math.max(cIdx + 1, resNext.mLen);
      const start = mIdx * mDur + cIdx * (mDur / mLen);

      return {
        mLen,
        tracks: resNext.tracks,
        sentence: getSentence(resNext, MARKS.wildcard),
        chart: getChart(resNext, ts, WILDCARD, start, resNext.end),
      };
    } else if (pattern[0] === MARKS.open) {
      const closeIdx = pattern.indexOf(MARKS.close);
      const sliceIdx = closeIdx < 1 ? pattern.length : closeIdx + 1;
      const value = cleanCarets(pattern.slice(0, sliceIdx));

      if (closeIdx < 1)
        return {
          mLen: cIdx + 1,
          tracks: getTracks(),
          sentence: getSentence({ sentence: [] }, value, ERRORS.open),
          chart: [],
        };
      pNext = pattern.slice(sliceIdx);

      const sound = getSound(pattern.slice(1, closeIdx));
      const ts = getTracks(sound);
      const resNext = parser(pNext, ts, mIdx, cIdx + 1, sound);
      const mLen = Math.max(cIdx + 1, resNext.mLen);
      const start = mIdx * mDur + cIdx * (mDur / mLen);

      return {
        mLen,
        tracks: resNext.tracks,
        sentence: getSentence(resNext, value, !sound && ERRORS.notation),
        chart: getChart(resNext, ts, sound, start, resNext.end, closeIdx),
      };
    } else if (pattern[0] === MARKS.close) {
      const resNext = parser(pNext, tracks, mIdx, cIdx + 1, MARKS.close);

      return {
        mLen: cIdx + 1,
        tracks: resNext.tracks,
        sentence: getSentence(resNext, MARKS.close, ERRORS.close),
        chart: resNext.chart,
      };
    } else if (pattern[0] === MARKS.start) {
      const endMatch = pattern.split('').reduce(
        (acc, c, idx) => {
          const found = acc[0] === 0 && acc[1] > 0;

          if (c === MARKS.start && !found) return [acc[0] + 1, acc[1]];
          else if (c === MARKS.end && !found) return [acc[0] - 1, idx];

          return acc;
        },
        [0, -1]
      );
      const endIdx = endMatch[0] === 0 && endMatch[1] > 0 ? endMatch[1] : -1;
      const sliceIdx = endIdx < 1 ? pattern.length : endIdx;
      const combo = pattern.slice(1, sliceIdx);
      const resCombo = parser(combo, {}, 0, 0);
      const ts = Object.keys(resCombo.tracks).reduce(
        (acc, id) => getTracks({ id } as unknown as Sound, acc),
        tracks
      );
      const lastNote = resCombo.chart[resCombo.chart.length - 1] ?? { end: 0 };
      const comboMs = lastNote.end
        ? Math.ceil(lastNote.end / mDur)
        : Math.floor(lastNote.start / mDur + 1);
      let mLen = cIdx + 1;
      let start = mIdx * mDur + cIdx * (mDur / mLen);
      let scale = 1 / mLen / comboMs;

      if (endIdx < 1)
        return {
          mLen,
          tracks: ts,
          sentence: getSentence(resCombo, MARKS.start, ERRORS.start),
          chart: resCombo.chart.map(getScaleNote(ts, start, scale)),
        };
      pNext = pattern.slice(sliceIdx + 1);

      const resNext = parser(pNext, ts, mIdx, cIdx + 1);
      const error = resCombo.sentence.some((w) => !!w.error) && ERRORS.nest;

      mLen = Math.max(mLen, resNext.mLen);
      start = mIdx * mDur + cIdx * (mDur / mLen);
      scale = 1 / mLen / comboMs;

      return {
        mLen,
        tracks: resNext.tracks,
        sentence: [
          ...getSentence(resCombo, MARKS.start, error),
          ...getSentence(resNext, MARKS.end, error),
        ],
        chart: [
          ...resCombo.chart.map(getScaleNote(resNext.tracks, start, scale)),
          ...resNext.chart,
        ],
      };
    } else if (pattern[0] === MARKS.end) {
      const resNext = parser(pNext, tracks, mIdx, cIdx + 1, MARKS.end);

      return {
        mLen: cIdx + 1,
        tracks: resNext.tracks,
        sentence: getSentence(resNext, MARKS.end, ERRORS.end),
        chart: resNext.chart,
      };
    } else if (pattern[0] === MARKS.caret) {
      const resNext = parser(pNext, tracks, mIdx, cIdx, prev);
      let start = mIdx * mDur;

      if (cIdx > 0 && (pattern[1] === MARKS.space || pattern.length === 1))
        start += (cIdx - 0.5) * (mDur / cIdx);
      else if (pattern.length > 1)
        start += cIdx * (mDur / Math.max(cIdx + 1, resNext.mLen));

      return {
        mLen: resNext.mLen,
        tracks: resNext.tracks,
        sentence: getSentence(resNext, MARKS.caret),
        chart: getChart(resNext, tracks, CARET, start),
        end: resNext.end,
      };
    }

    // Default
    const markIdxs = Object.values(MARKS)
      .filter((c) => c !== MARKS.caret)
      .map((c) => pattern.indexOf(c))
      .filter((i) => i >= 0);
    const minIdx = Math.min(...markIdxs, pattern.length);
    const match = [...Array(minIdx).keys()]
      .map((i) => pattern.slice(0, i + 1))
      .map((v) => [v, getSound(v)] as [string, Sound])
      .find((pair) => pair[1]);
    const notation = match?.[0] ?? pattern[0];
    const sound = match?.[1];

    pNext = pattern.slice(notation.length);

    const ts = getTracks(sound);
    const resNext = parser(pNext, ts, mIdx, cIdx + 1, sound);
    const mLen = Math.max(cIdx + 1, resNext.mLen);
    const start = mIdx * mDur + cIdx * (mDur / mLen);

    return {
      mLen,
      tracks: resNext.tracks,
      sentence: getSentence(resNext, notation, !sound && ERRORS.notation),
      chart: getChart(resNext, ts, sound, start, resNext.end, notation.length),
    };
  };

  return parser;
};

export default getParser;
