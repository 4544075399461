import React, { ReactElement } from 'react';

import Annotate from '~/components/Annotate';
import COLORS from '~/constants/colors';
import { FONT_WEIGHTS } from '~/constants/typography';
import { stopEventEffects } from '~/utils';

import { Sound, Sounds } from '../../../../../models';
import { MARKS, ERRORS } from '../../../../../constants/language';

interface AutocompleteProps {
  sounds: Sounds;
  chunk?: string;
  replaceChunk: (notation: string) => void;
}

function Autocomplete({
  sounds,
  chunk,
  replaceChunk,
}: AutocompleteProps): ReactElement {
  const getHintKey = (idx) => `BeatbloxScriptInputBoxAutocompleteHint${idx}`;

  // Handlers
  const handleMouseDown = (event) => {
    const notation =
      event.target.getAttribute('data-notation') ??
      event.target.parentElement.getAttribute('data-notation');

    stopEventEffects(event);
    replaceChunk(notation);
  };

  // Search
  const { space, open, ...safeMarks } = MARKS;
  const safeChunk = Object.values(safeMarks).reduce(
    (acc, c) => acc?.replace(c, ''),
    chunk
  );
  let matches: Sounds = [];

  if (safeChunk === MARKS.open) matches = sounds;
  else if (safeChunk) {
    const query = safeChunk.replace(open, '');

    matches = sounds
      .map((s) => {
        let score = 0;

        // TODO: improve match ranking method
        if (s.notation.includes(query))
          score += (4 * query.length) / s.notation.length;
        else if (s.notation.toLowerCase().includes(query.toLowerCase()))
          score += (3 * query.length) / s.notation.length;
        else if (s.name.includes(query))
          score += (2 * query.length) / s.name.length;
        else if (s.name.toLowerCase().includes(query.toLowerCase()))
          score += query.length / s.name.length;

        return [s, score] as [Sound, number];
      })
      .filter((pair) => pair[1])
      .sort((a, b) => Number(b[1]) - Number(a[1]))
      .map((pair) => pair[0]);
  }

  // Calculate
  const width = '25em';
  const maxCols = 3.5;
  const safeLength = Math.max(matches.length, 1);
  const height = Math.min(safeLength * 1.17, maxCols);
  const containerWidth = `calc(${width} - 2em)`;
  const containerHeight = `${height + 1.2}em`;
  const contentWidth = `calc(${width} - 3em)`;
  const contentHeight = `${height + 0.3}em`;

  // Styling
  const autocompleteStyle = {
    zIndex: 7,
  };
  const contentStyle = {
    width: contentWidth,
    height: contentHeight,
    padding: '0.5em',
  };
  const wrapperStyle = {
    width: contentWidth,
    height: contentHeight,
    lineHeight: '1.3em',
    color: COLORS.white,
    overflowY: 'scroll' as const,
    textAlign: 'left' as const,
    wordWrap: 'break-word' as const,
  };
  const hintStyle = {
    cursor: 'pointer',
  };
  const notationStyle = {
    padding: '0 0.2em',
    color: COLORS.tundora,
    backgroundColor: COLORS.alto,
    borderRadius: '0.2em',
    fontWeight: FONT_WEIGHTS.bold,
  };

  return (
    <Annotate
      visible={safeChunk && safeChunk !== space}
      containerWidth={containerWidth}
      containerHeight={containerHeight}
      containerBackgroundColor={COLORS.tundora}
      style={autocompleteStyle}
      contentStyle={contentStyle}
    >
      <div style={wrapperStyle}>
        {matches.length > 0 ? (
          matches.map((s, idx) => (
            <div
              key={getHintKey(idx)}
              role="none"
              data-notation={s.notation}
              onMouseDown={handleMouseDown}
              onTouchStart={handleMouseDown}
              style={hintStyle}
            >
              <span style={notationStyle}>{s.notation}</span> {s.name}
            </div>
          ))
        ) : (
          <>
            <span style={notationStyle}>{MARKS.missing}</span> {ERRORS.notation}
          </>
        )}
      </div>
    </Annotate>
  );
}

export default Autocomplete;
