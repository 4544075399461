import React, { ReactElement, Dispatch, SetStateAction, useState } from 'react';

import Modal from '~/components/Modal';
import Header from '~/components/titles/Header';
import Subtext from '~/components/titles/Subtext';
import Button from '~/components/buttons/Button';
import ToggleSwitch from '~/components/inputs/ToggleSwitch';
import Gap from '~/components/flow/Gap';
import Break from '~/components/flow/Break';
import VARIANTS from '~/constants/variants';
import { isEmptyObject } from '~/utils';
import Announcement from '~/components/Announcement';

import { CONSTRAINT_DESCRIPTIONS } from '../constants/media';
import { getResetReducer, getAudioConstraints } from '../utils';

interface SettingsProps {
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  config: Record<string, any>;
  setConfig: Dispatch<SetStateAction<Record<string, any>>>;
}

function Settings({
  visible,
  setVisible,
  config,
  setConfig,
}: SettingsProps): ReactElement {
  const [deviceOptions, setDeviceOptions] = useState(
    getAudioConstraints(config.audio)
  );
  const resetReducer = getResetReducer(config.audio);
  const getKey = (idx) => `BeatboxShareRecordSettingsToggleSwitch${idx}`;

  // Handlers
  const handleDeviceOptionsToggle = (option) => (checked) =>
    setDeviceOptions({ ...deviceOptions, [option]: checked });
  const handleSave = () => {
    setConfig({
      ...config,
      audio: deviceOptions,
    });
    setVisible(false);
  };
  const handleCancel = () => {
    setVisible(false);
    setDeviceOptions(Object.keys(deviceOptions).reduce(resetReducer, {}));
  };

  // Styling
  const toggleSwitchStyle = {
    margin: '0 0.3em 0 0.75em',
  };
  const modalButtonsStyle = {
    float: 'right' as const,
    marginTop: '-0.5em',
  };
  const saveStyle = {
    width: '5em',
    margin: '0.5em 0 0',
  };
  const cancelStyle = {
    ...saveStyle,
    margin: '0 0.5em 0 0',
  };

  return (
    <Modal visible={visible} title="Settings" hider={handleCancel}>
      {!isEmptyObject(deviceOptions) && (
        <>
          <Header>Device Options</Header>
          <Subtext>
            Will only apply to your <b>next</b> recording but generally better
            turned <b>off</b>
          </Subtext>
          <Gap />
          {Object.keys(deviceOptions).map((o, idx) => (
            <div key={getKey(idx)}>
              <Gap />
              <ToggleSwitch
                checked={deviceOptions[o]}
                onChange={handleDeviceOptionsToggle(o)}
                style={toggleSwitchStyle}
              />
              {CONSTRAINT_DESCRIPTIONS[o]}
            </div>
          ))}
        </>
      )}
      {isEmptyObject(deviceOptions) && (
        <Announcement variant={VARIANTS.warning}>
          Your browser does not support any options
        </Announcement>
      )}
      <Break />
      <div style={modalButtonsStyle}>
        <Button
          label="Cancel"
          variant={VARIANTS.secondary}
          callback={handleCancel}
          style={cancelStyle}
        />
        <Button label="Save" callback={handleSave} style={saveStyle} />
      </div>
    </Modal>
  );
}

export default Settings;
