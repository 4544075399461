import React, { Dispatch, SetStateAction, useState } from 'react';

import { stopEventEffects } from '~/utils';
import COLORS from '~/constants/colors';
import Select from '~/components/inputs/Select';
import { FONTS, FONT_SIZES, FONT_WEIGHTS } from '~/constants/typography';
import Gap from '~/components/flow/Gap';

import { Beat } from '../../../models';
import { BEAT } from '../../../constants/defaults';
import examples from '../examples';
import Publish from '../../../components/Publish';

interface FinishProps {
  beat: Beat;
  disabled?: boolean;
  setLoaded: Dispatch<SetStateAction<Beat>>;
}

function Finish({ beat, disabled, setLoaded }: FinishProps) {
  // Styling
  const finishStyle = {
    marginTop: '0.5em',
  };
  const selectStyles = {
    control: () => ({
      fontFamily: FONTS.typewriter,
      fontSize: FONT_SIZES.text,
      fontWeight: FONT_WEIGHTS.normal,
      borderColor: COLORS.platinum,
      boxShadow: `-0.07em 0.07em ${COLORS.black25}`,
      '&:hover': undefined,
    }),
    placeholder: () => ({
      whiteSpace: 'nowrap' as const,
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    }),
    dropdownIndicator: () => ({
      color: COLORS.doveGray,
      ':hover': undefined,
    }),
    container: () => ({
      zIndex: 6,
    }),
  };
  const clearStyle = {
    color: COLORS.silver,
    fontWeight: FONT_WEIGHTS.bold,
    fontSize: FONT_SIZES.subtext,
    cursor: 'pointer',
  };
  const gapStyle = {
    height: '1.25em',
  };

  // Example
  const [optionIdx, setOptionIdx] = useState<number>();
  const options = Object.keys(examples).map((k) => ({
    value: Number(k),
    label: examples[k].title,
  }));
  const handleExamples = (option) => {
    setLoaded(examples[option.value]);
    setOptionIdx(option.value);
  };
  const clearExample = (event) => {
    stopEventEffects(event);
    setLoaded(BEAT);
    setOptionIdx(undefined);
  };

  return (
    <div style={finishStyle}>
      <Select
        options={options}
        disabled={disabled}
        menuPlacement="auto"
        onChange={handleExamples}
        value={optionIdx !== undefined && options[optionIdx]}
        placeholder="Load an example"
        styles={selectStyles}
      />
      <Gap />
      <div
        role="button"
        tabIndex={-1}
        onMouseUp={clearExample}
        onTouchEnd={clearExample}
        style={clearStyle}
      >
        Clear Example
      </div>
      <Gap style={gapStyle} />
      <Publish beat={beat} disabled={disabled} />
    </div>
  );
}

export default Finish;
