import React, { useState, Dispatch, SetStateAction, useRef } from 'react';
import axios from 'axios';

import { mod, stopEventEffects } from '~/utils';
import { getArrayBuffer } from '~/helpers/media';
import COLORS from '~/constants/colors';
import Button from '~/components/buttons/Button';
import { FONT_SIZES } from '~/constants/typography';

import { Beat, Chart } from '../../../models';
import { getMaxTiming, extractSounds } from '../../../helpers';
import { TEMPO_NUMERATOR } from '../../../constants/defaults';
import { MARKS } from '../../../constants/language';

const AudioContext = window.AudioContext || (window as any).webkitAudioContext;

interface ListenProps {
  beat: Beat;
  chart: Chart;
  label?: string;
  setProgress?: Dispatch<SetStateAction<number>>;
}

function Listen({ beat, chart, label = 'Listen', setProgress }: ListenProps) {
  const [playing, setPlaying] = useState(false);
  const safeChart = chart
    .filter((n) => n.sound.sample.recording.url)
    .sort((a, b) => a.start - b.start);
  const carets = chart.filter((n) => n.sound.notation === MARKS.caret);
  const minTiming = carets.length > 1 ? carets[0].start : 0;
  const maxTiming = getMaxTiming(chart);
  const mpb = TEMPO_NUMERATOR / beat.bpm;
  const maxPaddedTiming =
    carets.length > 1 ? carets[1].start : maxTiming + mpb - mod(maxTiming, mpb);

  // Prepare
  const samples = useRef<AudioBufferSourceNode[][]>([]);
  const currentLoop = useRef(0);
  const prevIdxs = useRef<number[]>([]);
  const sustainIdxs = useRef<number[]>([]);
  const timer = useRef<ReturnType<typeof setTimeout>>();

  // Helpers
  const clearPrep = () => {
    prevIdxs.current.forEach((i) =>
      samples.current[currentLoop.current]?.[i].stop()
    );
    sustainIdxs.current.forEach((i) =>
      samples.current[currentLoop.current]?.[i].stop()
    );
    samples.current[currentLoop.current]?.forEach((b) => b.disconnect());
    setProgress?.(0);
    clearInterval(timer.current);
  };

  // Handlers
  const handleMouseDown = (event) => {
    if (carets.length > 0) stopEventEffects(event);
  };
  const stopChart = async (event) => {
    stopEventEffects(event);
    clearPrep();
    await (samples.current[0]?.[0].context as AudioContext).close?.();
    setPlaying(false);
  };
  const playChart = async (event) => {
    const context = new AudioContext();

    stopEventEffects(event);
    setPlaying(true);

    // Preload samples
    const sounds = extractSounds(chart);
    const audioBuffers = await Object.values(sounds).reduce(async (accP, s) => {
      const { url } = s.sample.recording;
      const acc = await accP;
      const response =
        url && (await axios.get(url, { responseType: 'blob' }).catch(() => {}));

      if (response) {
        const blob = response.data;
        const arrayBuffer = await getArrayBuffer(blob);

        return { ...acc, [s.id]: await context.decodeAudioData(arrayBuffer) };
      }

      return acc;
    }, Promise.resolve({}));

    samples.current = [...Array(Number(beat.loops))].map(() =>
      safeChart.map((n) => {
        const bufferSourceNode = new AudioBufferSourceNode(context, {
          ...(audioBuffers[n.sound.id] && { buffer: audioBuffers[n.sound.id] }),
          ...(n.end && { loop: true }),
        });

        bufferSourceNode.connect(context.destination);

        return bufferSourceNode;
      })
    );

    // Play sounds with timer
    currentLoop.current = 0;

    const startTimer = () => {
      const startTime = Date.now();
      const minNoteIdx = safeChart.findIndex((n) => n.start >= minTiming);
      let noteIdx = carets.length > 1 && minNoteIdx > 0 ? minNoteIdx : 0; // Represents first note not yet played

      prevIdxs.current = [];
      sustainIdxs.current = [];
      timer.current = setInterval(() => {
        const currentTime = Date.now() - startTime + minTiming;

        setProgress?.(currentTime);

        // Stop previous samples
        if (currentTime >= safeChart[noteIdx]?.start) {
          prevIdxs.current.forEach((i) =>
            samples.current[currentLoop.current]?.[i].stop()
          );
          prevIdxs.current = [];
        }

        // Manage sustains
        sustainIdxs.current.forEach((i) => {
          if ((safeChart[i].end ?? 0) <= currentTime)
            samples.current[currentLoop.current]?.[i].stop();
        });
        sustainIdxs.current = sustainIdxs.current.filter(
          (i) => (safeChart[i].end ?? 0) > currentTime
        );

        // Play next samples
        while (noteIdx < safeChart.length) {
          const note = safeChart[noteIdx];

          if (currentTime >= note.start) {
            samples.current[currentLoop.current]?.[noteIdx].start();
            if (note.end) sustainIdxs.current.push(noteIdx);
            else prevIdxs.current.push(noteIdx);
            noteIdx += 1;
          } else break;
        }

        // End playing
        if (currentTime > maxPaddedTiming) {
          clearPrep();
          currentLoop.current += 1;
          if (currentLoop.current < beat.loops) startTimer();
          else {
            if (context.state !== 'closed') context.close();
            setPlaying(false);
          }
        }
      }, 1);
    };
    startTimer();
  };

  // Styling
  const baseStyle = {
    width: '4.3em',
    padding: '0.3em',
    borderRadius: '0.3em',
    fontSize: FONT_SIZES.text,
    boxShadow: `-0.07em 0.07em ${COLORS.black25}`,
  };
  const listenStyle = {
    ...baseStyle,
    backgroundColor: COLORS.doveGray,
    borderColor: COLORS.doveGray,
  };
  const stopStyle = {
    ...baseStyle,
    backgroundColor: COLORS.mandy,
    borderColor: COLORS.mandy,
  };
  const disabledStyle = {
    backgroundColor: COLORS.gallery,
    borderColor: COLORS.gallery,
  };
  const disabledLabelStyle = {
    color: COLORS.gray,
  };

  return (
    <Button
      label={playing ? 'Stop' : label}
      disabled={safeChart.length === 0}
      callback={playing ? stopChart : playChart}
      onMouseDown={handleMouseDown}
      onTouchStart={handleMouseDown}
      style={playing ? stopStyle : listenStyle}
      disabledStyle={disabledStyle}
      disabledLabelStyle={disabledLabelStyle}
    />
  );
}

export default Listen;
